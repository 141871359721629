<template>
  <transition name="fade">
    <div class="modal-mask" v-if="showModal" @click="showModal = !closable"
      @keyup.esc="showModal = !closable">
      <div :class="'modal ' + position" tabindex="-1" role="dialog"
        aria-labelledby="modal-label">
        <div class="modal-dialog" role="document" @click.stop>
          <div class="modal-content">
            <div class="modal-body">
              <component :is="child" v-bind="childProps" :id="id" :parent-id="parent_id"
                ref="form" @close="showModal = false" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: "modal",
    data() {
      return {
        showModal: false,
        title: "",
        child: "",
        childProps: {},
        position: "fixed-center",
        id: undefined,
        parent_id: 100,
        closable: true,
      };
    },
    watch: {
      showModal(value) {
        document.body.style.overflow = value ? "hidden" : "";
      },
    },
    mounted() {
      document.querySelectorAll("[data-form]").forEach((el) => {
        const form = el.getAttribute("data-form");
        const id = el.getAttribute("data-id");
        const parentId = el.getAttribute("data-parent-id");
        const position = el.getAttribute("data-position");
        el.addEventListener("click", () => this.show(form, id, parentId, position));
      });
      window.$modal = this;
    },
    methods: {
      show(child, id, parent_id, position = "fixed-center", childProps = {},
        _closable = true) {
        this.child = child;
        this.childProps = childProps;
        this.id = id;
        this.parent_id = parent_id;
        this.position = position || this.position;
        this.showModal = true;
        this.closable = _closable;
        this.$nextTick(() =>
          this.title = this.$refs.form.title && this.$refs.form.title()
        );
      },
    },
  };
</script>

<style lang="scss">
  /* stylelint-disable max-nesting-depth */
  .modal-mask {
    background-color: rgba(50,50,70,0.7);
    height: 100%;
    left: 0;
    overflow-y: auto;
    position: fixed;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    top: 0;
    transition: all 0.5s ease;
    width: 100%;
    z-index: 9998;
    &.fade-enter,
    &.fade-leave-to {
      .fixed-center {
        transform: translateY(-2%);
      }
      .fixed-right {
        transform: translateX(40%);
      }
    }
    .modal {
      display: block;
      overflow: hidden;
      transform: none;
      transition: transform 0.5s ease;
      z-index: 1050;
      background: transparent;

      &.fixed-center {
        position: static;
      }

      .modal-header {
        padding: 24px 24px 24px;
        .modal-close {
          background: transparent;
          border: 0;
          cursor: pointer;
          float: right;
          font-size: 21px;
          font-weight: bold;
          opacity: 0.54;
          padding: 0;
          text-shadow: 0 1px 0 white;
          &:hover {
            opacity: 1;
          }
        }
        .modal-title {
          color: black;
          font-size: 20px;
          font-weight: 500;
        }
      }

      &.fixed-center {
        margin: 0 auto;
        max-width: 70vw;
        overflow: visible;
        .modal-dialog {
          width: 40vw;
          max-width: 100vw !important;
        }
        .modal-body {
          padding: 0;
        }
      }
      &.modal-large {
        .modal-dialog {
          width: 70vw;
        }
      }
    }

    .fixed-right {
      height: 100vh;
      .modal-dialog {
        margin: 30px 0 30px auto;
        height: 100vh;
        width: 40vw;
        max-width: 100vw !important;
        .modal-content {
          height: 100%;
        }
      }
      .modal-template-info {
        height: 90vh;
        padding-right: 0 !important;
      }
    }
  }
</style>
