<template>
  <div>
    <div class="form-container">
      <input-box v-model="item.email" disabled placeholder="Email" title="Email" />

      <div class="flex between center">
        <div class="f-48">
          <input-box v-model="item.first_name" placeholder="Nome" title="Nome" />
        </div>
        <div class="f-48">
          <input-box v-model="item.last_name" placeholder="Sobrenome" title="Sobrenome" />
        </div>
      </div>

      <input-box v-model="item.password" type="password" placeholder="Nova senha" title="Nova senha" :error="errors.password && errors.password[0]"/>

      <input-box v-model="item.password_confirmation" type="password" placeholder="Confirmação de nova senha" title="Confirmação de nova senha" />

      <p v-if="errors.password_mismatch" class="danger-text p-5">
        {{errors.password_mismatch[0]}}
      </p>

      <div class="form-actions flex center end my-5">
        <router-link to="/dashboards">
          <p class="btn btn-secondary px-5 mr-3">Voltar</p>
        </router-link>
        <p class="btn btn-primary main" @click="save">Atualizar dados</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "account-form",
    data() {
      return {
        item: {
          first_name: "",
          last_name: "",
          password: "",
          password_confirmation: "",
          email: "",
        },
        errors: [],
      };
    },
    computed: {

    },
    mounted() {
      this.fetchData();
    },
    methods: {
      async fetchData() {
        try {
          const response = await axios.get(`/api/v1/accounts/edit`);
          this.item = response.data.user
        } catch {
          console.log("Error in fetching users/edit")
        }
      },
      async save() {
        if (this.userLimitReachedCondition) {
          $snackbar.create("Você atingiu o limite de usuários no projeto. Se deseja aumentar o limite de usuários, entre em contato com a Master BI", { activeClass: "danger" });
          return;
        }
        try {
          const response = await axios.patch(`/api/v1/accounts`, { user: this.item });
          if (response.data.success) {
            // this.$router.push({name: "dashboard-list"})
            $snackbar.create("Dados atualizados com sucesso", { activeClass: "success" });
            setTimeout(() => {
              if (this.item.password.length) {
                alert("Como você atualizou sua senha, será necessário efetuar o login novamente. A página será recarregada.")
                window.location = "/"
              }
            }, 1000)
          } else {
            this.errors = response.data.errors;
            $snackbar.create("Erro ao atualizar usuário", { activeClass: "danger" });
          }
        }
        catch {
          $snackbar.create("Erro ao atualizar usuário", { activeClass: "danger" });
        }
      },
      async deleteUser() {
        window.$modal.show("user-delete-modal", null, null, "fixed-center",
          { user: this.item, userId: this.userId, currentRoles: this.currentRoles });
      }
    },
  };
</script>

<style lang="scss" scoped>
  .icon {
    &.active {
      background-color: rgb(220,220,220)
    }
  }
</style>

<style lang="scss">
  .warning-reminder {
    background-color: #F0AD4E;
    .checkbox-c {
      div {
        color: white !important;
        i {
          color: white !important;
        }
        p {
          color: white !important;
        }
      }
    }
  }
</style>
