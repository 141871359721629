<template>
  <div>
    <div class="flex end center">
      <p class="btn btn-primary" @click="newBusiness">Cadastrar empresa</p>
    </div>

    <div class="list">
      <div class="list-headers flex center">
        <p class="f-20">Nome</p>
        <p class="f-20">Contato principal</p>
        <p class="f-20 text-center">Usuários</p>
        <p class="f-20 text-center">Dashboards</p>
        <p class="f-20 text-center">Ticket mensal</p>
      </div>
      <div class="dashboard list-item clickable flex center py-4" v-for="business in businesses"
        @click="gotoBusinessManage(business)">
        <p class="f-20 bold">{{business.name}}</p>
        <div class="f-20">
          <p class="m-0 bold">{{business.main_contact_name}}</p>
          <p class="m-0">{{business.main_contact_email}}</p>
        </div>
        <p class="f-20 m-0 text-center">{{business.current_user_count}} / {{business.user_count}}</p>
        <p class="f-20 m-0 text-center">{{business.dashboard_count}}</p>
        <p class="f-20 m-0 text-center">{{business.pricing}}</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "business-list",
    data() {
      return {
        businesses: [],
      };
    },
    mounted() {
      this.fetchBusinesses();
    },
    methods: {
      async fetchBusinesses() {
        try {
          const response = await axios.get("/api/v1/admin/businesses");
          this.businesses = response.data.businesses
        } catch {
          console.log("Error in fetching business")
        }
      },
      newBusiness() {
        this.$router.push({ name: "business-form" })
      },
      gotoBusinessManage(business) {
        const path = `/businesses/${business.id}/panel`
        this.$router.push({ path })
      }
    },
  };
</script>


