<template>
  <div class="wrapper">
    <!-- <div id="powerbi-container"></div> -->
    <div id="iframe-container" v-if="loaded">
      <fullscreen id="fullscreen" ref="fullscreen" @change="fullscreenChange">
        <iframe :src="dashboard.iframe_url" frameborder="0" id="iframe" allowfullscreen="true">

        </iframe>
      </fullscreen>
    </div>
  </div>
</template>

<script>
  export default {
    name: "dashboard-show",
    data() {
      return {
        dashboard: null,
        loaded: false,
        fullscreen: false,
        view: null,
      };
    },
    created() {
      this.$eventHub.$on('fullscreen', this.toggleFullscreen);
    },
    mounted() {
      this.fetchDashboard();

      window.addEventListener('beforeunload', (event) => {
        this.finishView();
      });
    },
    beforeDestroy() {
      this.finishView();
      this.$eventHub.$off('fullscreen');
    },
    computed: {
      dashboardId() {
        return this.$route.params.id
      },
      resourceUrl() {
        return `/api/v1/dashboards/${this.dashboardId}`
      },
      screenControls() {
        return this.controls.filter((control) => this.$route.name == control.name && control.access.includes(this.$store.state.userData.access))
      },
    },
    methods: {
      async finishView() {
        await axios.delete(`/api/v1/views/${this.view.id}`);
      },
      toggleFullscreen () {
        this.$refs['fullscreen'].toggle()
      },
      fullscreenChange (fullscreen) {
        this.fullscreen = fullscreen
      },
      async fetchDashboard() {
        try {
          const response = await axios.get(this.resourceUrl);
          if (response.data.error) {
            $snackbar.create(response.data.error, { activeClass: "danger" });
          } else {
            this.dashboard = response.data.dashboard;
            this.view = response.data.view
            this.loaded = true;
          }
        } catch {
          console.log("Error in fetching dashboard")
        }
      },
      dashboardInfo(dashboard) {
        window.$modal.show("dashboard-info-modal", null, null, "fixed-right", { dashboard });
      },
      // mountDashboardUsingEmbedded() {
      //   let txtAccessToken = this.dashboard.token;
      //   let txtEmbedUrl = this.dashboard.url
      //   let txtEmbedReportId = this.dashboard.powerbi_report_id

      //   let models = window['powerbi-client'].models;
      //   let permissions = models.Permissions.All;
      //   let config = {
      //     type: 'report',
      //     tokenType: models.TokenType.Embed,
      //     accessToken: txtAccessToken,
      //     embedUrl: txtEmbedUrl,
      //     id: txtEmbedReportId,
      //     permissions: permissions,
      //     settings: {
      //       panes: {
      //         filters: {
      //           visible: false
      //         },
      //         pageNavigation: {
      //           visible: true
      //         }
      //       }
      //     }
      //   };
      //   let embedContainer = document.querySelector("#powerbi-container")

      //   let report = powerbi.embedNew(embedContainer, config);
      // },
    },
  };
</script>

<style lang="scss" scoped>
  #iframe-container {
    height: 100%;
    width: 100%;
  }
  #iframe {
    height: 100%;
    width: 100%;
  }
  #fullscreen {
    height: 100%;
    width: 100%;
  }
  .wrapper {
    height: 98%;
    width: 100%;
    z-index: 100;
  }
</style>
