import VueRouter from "vue-router";
import Vue from 'vue'

const lazy = (name) => () => Promise.resolve(Vue.component(name));

// Load all components in javascript/packs/components

const requireComponents = require.context("./components", true, /.vue$/);
const registeredComponents = []

requireComponents.keys().forEach((fileName) => {
  const component = requireComponents(fileName);
  const componentName = component.default.name;

  registeredComponents.push(componentName)

  if (component.default.el === "application") {
    return;
  }

  Vue.component(componentName, component.default || component);
});

const components = [
  {
    path: "/home",
    component: lazy('home'),
    name: "home",
  },
  {
    path: "/roles",
    component: lazy('roles-list'),
    name: "roles-list",
    meta: {
      breadcrumb: ["roles-list"]
    }
  },
  {
    path: "/roles/new",
    component: lazy('roles-form'),
    name: "roles-form",
    meta: {
      breadcrumb: ["roles-list", "roles-form"]
    }
  },
  {
    path: "/roles/:id/edit",
    component: lazy('roles-form'),
    name: "roles-form",
    meta: {
      breadcrumb: ["roles-list", "roles-form"]
    }
  },
  {
    path: "/views/:entity_class/:id",
    component: lazy('views-list'),
    name: "views-list",
    meta: {
      breadcrumb: ["views-list"]
    }
  },
  {
    path: "/themes",
    component: lazy('themes-list'),
    name: "themes-list",
    meta: {
      breadcrumb: ["themes-list"]
    }
  },
  {
    path: "/themes/new",
    component: lazy('themes-form'),
    name: "themes-form",
    meta: {
      breadcrumb: ["themes-list", "themes-form"]
    }
  },
  {
    path: "/themes/:id/edit",
    component: lazy('themes-form'),
    name: "themes-form",
    meta: {
      breadcrumb: ["themes-list", "themes-form"]
    }
  },
  {
    path: "/users",
    component: lazy('users-list'),
    name: "users-list",
    meta: {
      breadcrumb: ["users-list"]
    }
  },
  {
    path: "/users/new",
    component: lazy('users-form'),
    name: "users-form",
    meta: {
      breadcrumb: ["users-list", "users-form"]
    }
  },
  {
    path: "/users/:id/edit",
    component: lazy('users-form'),
    name: "users-form",
    meta: {
      breadcrumb: ["users-list", "users-form"]
    }
  },
  {
    path: "/reports",
    component: lazy('report-list'),
    name: "report-list",
    meta: {
      breadcrumb: ["report-list"]
    }
  },
  {
    path: "/reports/new",
    component: lazy('report-form'),
    name: "report-form",
    meta: {
      breadcrumb: ["report-list", "report-form"]
    }
  },
  {
    path: "/reports/:id/edit",
    component: lazy('report-form'),
    name: "report-form",
    meta: {
      breadcrumb: ["report-list", "report-form"]
    }
  },
  {
    path: "/businesses",
    component: lazy('business-list'),
    name: "business-list",
    meta: {
      breadcrumb: ["business-list"]
    }
  },
  {
    path: "/businesses/new",
    component: lazy('business-form'),
    name: "business-form",
    meta: {
      breadcrumb: ["business-list", "business-form"]
    }
  },
  {
    path: "/my-account",
    component: lazy('account-form'),
    name: "account-form",
    meta: {
      breadcrumb: ["account-form"]
    }
  },
  {
    path: "/businesses/:id/panel",
    component: lazy('business-panel'),
    name: "business-panel",
    meta: {
      breadcrumb: ["business-list", "business-panel"]
    }
  },
  {
    path: "/businesses/:business_id/dashboards/new",
    component: lazy('business-dashboards-form'),
    name: "business-dashboards-form",
    meta: {
      breadcrumb: ["business-list", "business-panel", "business-dashboards-new"]
    }
  },
  {
    path: "/businesses/:business_id/dashboards/:id/edit",
    component: lazy('business-dashboards-form'),
    name: "business-dashboards-form",
    meta: {
      breadcrumb: ["business-list", "business-panel", "business-dashboards-edit"]
    }
  },
  {
    path: "/dashboards/new",
    component: lazy('dashboards-form'),
    name: "dashboards-form",
    meta: {
      breadcrumb: ["dashboard-list", "dashboard-new"]
    }
  },
  {
    path: "/dashboards/:id/edit",
    component: lazy('dashboards-form'),
    name: "dashboards-form",
    meta: {
      breadcrumb: ["dashboard-list", "dashboard-edit"]
    }
  },
  {
    path: "/dashboards",
    component: lazy('dashboard-list'),
    name: "dashboard-list",
    meta: {
      breadcrumb: ["dashboard-list"]
    }
  },
  {
    path: "/dashboards/:id",
    component: lazy('dashboard-show'),
    name: "dashboard-show",
    meta: {
      breadcrumb: ["dashboard-list", "dashboard-show"]
    }
  },
  {
    path: "/page-not-found",
    component: lazy('page-not-found'),
    name: "page-not-found",
  },
]

const matchRoutes = (route, path) => {
  // Route is the route accessed by user; path is th component path
  const routeSplit = route.split("/").filter((el) => !!el)
  const pathSplit = path.split("/").filter((el) => !!el)

  if (routeSplit.length !== pathSplit.length) return false;

  return routeSplit.every((routePart, index) => {
    const correspPath = pathSplit[index]
    return correspPath.includes(":") ? true : routePart === correspPath
  })
}

let findComponent = (path) => {
  const component = components.find((c) => matchRoutes(path, c.path) )
  if (!component) return null
  return component.name
}

const router = new VueRouter({
  mode: "history",
  routes: [
    // ---------------------------
    // Redirect logic
    // ---------------------------
    {
      path: '/',
      name: 'Redirect',
      redirect: function (to) {
        const path = to.query.redirect
        if (typeof path === 'undefined') {
          return {
            path: '/dashboards',
            query: ''
          }
        } else if (registeredComponents.includes(findComponent(path))) {
          return {
            path: `/${to.query.redirect}`,
            query: ''
          }
        } else {
          return {
            path: '/page-not-found',
            query: ''
          }
        }
      }
    },
    ...components
  ],
});

export default router;
