<template>
  <div class="form-container">
    <input-box v-model="item.name" placeholder="Nome" title="Nome"/>

    <input-box v-model="item.summary" placeholder="Resumo" title="Resumo"/>

    <text-area v-model="item.description" placeholder="Descrição" title="Descrição" height="500px"/>

    <div class="form-actions flex center end">
      <router-link to="/reports">
        <p class="btn btn-secondary px-5 mr-3">Voltar</p>
      </router-link>
      <p class="btn btn-danger mx-3" v-if="id" @click="deleteReport">Deletar report</p>
      <p class="btn btn-primary main" @click="save">{{saveBtnText}}</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: "report-form",
    data() {
      return {
        item: {
          name: "",
          summary: "",
          description: "",
        },
        canDelete: false,
      };
    },
    computed: {
      id() {
        return this.$route.params.id;
      },
      saveBtnText() {
        if (this.id) {
          return "Atualizar report"
        } else {
          return "Inserir report"
        }
      },
    },
    mounted() {
      if (this.id) this.fetchReport();
    },
    methods: {
      async fetchReport() {
        try {
          const response = await axios.get(`/api/v1/admin/reports/${this.id}/edit`);
          this.item = response.data.report;
          this.canDelete = response.data.can_delete;
        } catch {
          console.log("Error in fetching roles/edit")
        }
      },
      async save() {
        try {
          if (this.id) {
            const response = await axios.patch(`/api/v1/admin/reports/${this.id}`, { report: this.item });
            if (response.data.success) {
              this.$router.push({name: "report-list"})
              $snackbar.create("Report atualizado com sucesso", { activeClass: "success" });
            } else {
              this.errors = response.data.errors;
              $snackbar.create("Erro ao atualizar report", { activeClass: "danger" });
            }
          } else {
            const response = await axios.post("/api/v1/admin/reports", { report: this.item });
            if (response.data.success) {
              this.$router.push({name: "report-list"})
              $snackbar.create("Report criado com sucesso", { activeClass: "success" });
            } else {
              $snackbar.create("Erro ao criar report", { activeClass: "danger" });
              this.errors = response.data.errors;
            }
          }
        }
        catch {
          console.log("Error in saving report")
        }
      },
      async deleteReport() {
        if (!this.canDelete) {
          alert("Você não pode deletar esse relatório, pois ele está associado com dashboards na plataforma")
          return;
        } else {
          const proceed = confirm(`Tem certeza que deseja excluir o report ${this.item.name}?`)

          if (proceed) {
            try {
              const response = await axios.delete(`/api/v1/admin/reports/${this.id}`);
              if (response.data.success) {
                $snackbar.create("Report removido com sucesso", { activeClass: "success" });
                this.$router.push({name: "report-list"})
              } else {
                $snackbar.create("Erro ao remover report", { activeClass: "danger" });
              }
            } catch {
              console.log("Error in deleting report")
            }
          }
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
  .icon {
    &.active {
      background-color: rgb(220,220,220)
    }
  }
</style>

