import "bootstrap";

import Vue from 'vue'
import App from '../app.vue'
import VueRouter from "vue-router";
import router from "./router.js";
import Vuex from "vuex";
import Multiselect from 'vue-multiselect'
import fullscreen from 'vue-fullscreen'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'

Vue.prototype.$eventHub = new Vue();
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(fullscreen)
Vue.use(require('vue-moment'));

Vue.component('multiselect', Multiselect)

// Load dependencies

import SnackbarLight from "snackbarlightjs";

window.$snackbar = SnackbarLight;

// Store

const store = new Vuex.Store({
  state: {
    userData: {}
  },
  mutations: {
    setUserData(state, payload) {
      state.userData = payload;
    }
  }
});

Vue.mixin({
  computed: {
    themeStyle() {
      return this.$store.state.userData && this.$store.state.userData.theme && {
        primary: `#${this.$store.state.userData.theme.primary}`,
        primary2: `#${this.$store.state.userData.theme.primary2}`,
        secondary: `#${this.$store.state.userData.theme.secondary}`,
        secondary2: `#${this.$store.state.userData.theme.secondary2}`,
        background: `#${this.$store.state.userData.theme.background}`,
        support: `#${this.$store.state.userData.theme.support}`,
      }
    },
    logoUrl() {
      return this.$store.state.userData && this.$store.state.userData.logo;
    },
    isCustomerAdmin() {
      return this.$store.state.userData && this.$store.state.userData.access === "customer_admin";
    }
  }
})

// Load application

import Application from "../app.vue";
if (document.querySelector("application"))
  new Vue(Object.assign({}, Application, { router, store }));
