<template>
  <div style="height: 40px">
    <select class="select" @change="onChange($event)">
      <option :value="null" selected class="placeholder">{{placeholder}}</option>
      <option v-for="option in options" :value="option[keyName]"
        class="option">
        {{option[name]}}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "filter-select",
  props: ["options", "keyName", "name", "placeholder"],
  data: function () {
    return {}
  },
  methods: {
    onChange(event) {
      this.$emit('input', event.target.value)
    }
  }
}
</script>

<style scoped lang="scss">
  .select {
    width: 300px;
    padding: 0 12px;
    height: 100%;
    border: 1px solid #ddd; /* Add a grey border */
    border-radius: 4px;
    background: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 7px;

    .placeholder {
      color: rgb(130,130,130);
    }

    .option {
      color: rgb(50,50,50);
      padding: 4px 0;
      border-bottom: 0.5px solid rgb(230,230,230);
    }
  }

</style>
