<template>
  <div>
    <p v-if="businessUserCount" :class="userCountClass" class="mt-3 mb-0 text-center p-4">{{currentUserCount}} de {{businessUserCount}} usuários no projeto</p>

    <div class="flex start my-4">
      <div class="flex start center f-80">
        <search v-model="filter_params.name" class="f-50 mr-4" placeholder="Filtrar usuários por nome ou email"/>
        <filter-select v-model="filter_params.role_id" :options="roles" key-name="id" name="name"
          placeholder="Todos os níveis de acesso" />
      </div>
    </div>

    <div class="list">
      <div class="list-headers flex center">
        <p class="f-20">Nome</p>
        <p class="f-50">Níveis de acesso</p>
        <p class="f-20">Convidado por</p>
        <p class="f-10">Histórico de visualizações</p>
      </div>
      <div class="dashboard list-item clickable flex center py-4" v-for="user in users"
        @click="goToUserEdit(user)">
        <div class="m-0 f-20">
          <p class="m-0 bold">
            <i v-if="user.customer_admin" class="fas fa-user-tie fs-20"></i>
            {{user.name}}
          </p>
          <p class="m-0 gs-90">{{user.email}}</p>
        </div>
        <div class="m-0 f-50">
          <div v-if="user.customer_admin" class="flex center start wrap fs-12">
            <p class="success-text br-8 px-5">Acesso a todos os dashboards</p>
          </div>
          <div v-else class="flex center start wrap fs-12">
            <p v-for="role in user.roles" class="role px-4 py-1 mr-3 br-8">
              <i class="fa mr-2" :class="role.icon"></i>
              <span>{{role.name}}</span>
            </p>
          </div>
        </div>
        <div class="m-0 f-20">
          <p class="m-0 gs-30">{{user.invited_by_name}}</p>
          <p class="m-0 gs-70">{{user.invited_at}}</p>
        </div>
        <div class="m-0 f-10">
          <p class="gs-90 pl-4" @click.stop="goToUserViews(user)">
            <i class="fa fa-eye fs-24 circle-hover"></i>
          </p>
        </div>
      </div>
    </div>

    <div class="flex center around h-10 mt-5">
      <pagination v-if="pagination" :page-data="pagination" @input="changePage"/>
    </div>
  </div>
</template>

<script>
  import VueLodash from 'vue-lodash'
  import lodash from 'lodash'

  export default {
    name: "users-list",
    data() {
      return {
        users: [],
        businessUserCount: 0,
        currentUserCount: 0,
        filter_params: {
          name: "",
          role_id: null,
        },
        roles: [],
        page: 1,
        pagination: null,
      };
    },
    mounted() {
      this.fetchUsers();
    },
    watch: {
      filter_params: {
        deep: true,
        handler() {
          this.page = 1;
          this.refresh();
        },
      },
    },
    computed: {
      userCountClass() {
        if (this.users.length < this.businessUserCount * 0.6) {
          return "success-text"
        } else if (this.users.length < this.businessUserCount * 0.9) {
          return "warning-text"
        } else {
          return "danger-text"
        }
      },
      userCountLimitReached() {
        return this.users.length >= this.businessUserCount
      },
    },
    methods: {
      refresh: lodash.debounce(function refresh() {
        this.fetchUsers();
      }, 500),
      async fetchUsers() {
        const params = {
          page: this.page,
          name: this.filter_params.name,
          role_id: this.filter_params.role_id,
        }

        try {
          const response = await axios.get("/api/v1/users", { params });
          this.users = response.data.users;
          this.roles = response.data.roles;
          this.pagination = response.data.pagination;
          this.currentUserCount = response.data.current_user_count;
          this.businessUserCount = response.data.business_user_count
        } catch {
          console.log("Error in fetching users")
        }
      },
      goToUserEdit(user) {
        this.$router.push({ path: `users/${user.id}/edit` })
      },
      goToUserViews(user) {
        this.$router.push({ path: `/views/user/${user.id}`})
      },
      changePage(page) {
        this.page = page
        this.fetchUsers();
      },
      newUser() {
        if (this.userCountLimitReached) {
          $snackbar.create("Você atingiu o limite de usuários no projeto. Se deseja aumentar o limite de usuários, entre em contato com a Master BI", { activeClass: "danger" });
          return;
        }

        this.$router.push( { path: `users/new` } )
      },
    },
  };
</script>

<style lang="scss" scoped>
  .role {
    background-color: rgb(220,220,220);
  }
</style>
