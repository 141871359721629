<template>
  <div>
    <multiselect :value="options.find(o => o[keyName] === value)" @select="emitEvent" :options="options" @remove="remove" :label="label" :placeholder="placeholder"/>
    <p v-if="error" class="text-danger pl-2 mt-2 error-content">{{error}}</p>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  export default {
    name: "select-box",
    components: { Multiselect },
    props: ["value", "placeholder", "options", "keyName", "label", "error"],
    data() {
      return {};
    },
    methods: {
      emitEvent(e) {
        this.$emit("input", e[this.keyName])
      },
      remove(e) {
        this.$emit("input", null)

      }
    },
  };
</script>

<style lang="scss" scoped>

</style>

