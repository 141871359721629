<template>
  <div>
    <div class="form-container">
      <h3>Gerenciar dashboard</h3>

      <input-box v-model="item.name" placeholder="Nome" title="Nome"
        :error="errors.name"/>

      <input-box v-model="item.summary" placeholder="Resumo" title="Resumo"
        :error="errors.summary"/>

      <text-area v-model="item.description" placeholder="Descrição" title="Empresa"height="300px"/>

      <h3>URL do Iframe</h3>

      <p class="btn btn-primary w-100" @click="dashboardInputModal">Definir URL do Iframe</p>

      <input-box v-model="item.iframe_url" disabled placeholder="URL do Iframe" title="URL do Iframe"
        :error="errors.iframe_url"/>

      <div class="spacer-m"></div>

      <h3>Prévia</h3>

      <div class="w-100">
        <div v-if="item.preview_pic">
          <img :src="item.preview_pic" alt="" class="w-100 img-fit">
        </div>
        <p v-else>Não há uma prévia para esse dashboard</p>
      </div>

      <p class="my-5">Nova prévia:</p><input type="file" ref="inputFile" @change="uploadFile">
      <img ref="previewImg" :src="item.preview_pic" alt="" class="w-100 img-fit mt-5">

      <div class="form-actions mt-5 flex center end">
        <router-link :to="`/dashboards`">
          <p class="btn btn-secondary px-5 mr-3">Voltar</p>
        </router-link>
        <p class="btn btn-danger mx-3" v-if="id" @click="deleteDashboard">Deletar dashboard</p>
        <p class="btn btn-primary main" @click="save">{{saveBtnText}}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  export default {
    name: "dashboards-form",
    components: { Multiselect },
    data() {
      return {
        item: {
          name: "",
          summary: "",
          description: "",
          iframe_url: "",
          preview: null,
          preview_pic: "",
        },
        errors: {},
        reports: [],
      };
    },
    computed: {
      id() {
        return this.$route.params.id;
      },
      saveBtnText() {
        return this.id ? "Atualizar dashboard" : "Inserir dashboard"
      }
    },
    mounted() {
      if (this.id) this.fetchDashboard();
    },
    created() {
      this.$eventHub.$on('input-url', this.inputUrl);
    },
    beforeDestroy() {
      this.$eventHub.$off('input-url');
    },
    methods: {
      inputUrl(event) {
        this.item.iframe_url = event;
      },
      uploadFile() {
        this.item.preview = this.$refs.inputFile.files[0];

        this.$refs.previewImg.src = window.URL.createObjectURL(this.item.preview)
      },
      dashboardInputModal() {
        window.$modal.show("dashboard-input-modal", null, null, "fixed-center modal-large", {});
      },
      async fetchDashboard() {
        try {
          const response = await axios.get(`/api/v1/dashboards/${this.id}/edit`);
          this.item = response.data.dashboard
        } catch {
          console.log("Error in fetching dashboards/edit")
        }
      },
      async save() {
        let formData = new FormData()

        formData.set('name', this.item.name)
        formData.set('summary', this.item.summary)
        formData.set('description', this.item.description)
        formData.set('iframe_url', this.item.iframe_url)
        formData.append('preview', this.item.preview)

        const request = await axios({
          method: this.id ? "patch" : "post",
          url: `/api/v1/dashboards/${this.id ? this.id : ''}`,
          data: formData,
          headers: {'Content-Type': 'multipart/form-data' }
        })

        try {
          const response = await request;
          if (response.data.success) {
            this.$router.push({path: `/dashboards`})
            $snackbar.create(`Dashboard ${this.id ? "atualizado" : "inserido"} com sucesso`, { activeClass: "success" });
          } else {
            this.errors = response.data.errors;
            $snackbar.create(`Erro ao ${this.id ? "atualizar" : "inserir"} dashboard`, { activeClass: "danger" });
          }
      }
        catch {
          console.log("Error in saving dashboard")
        }
      },
      async deleteDashboard() {
        window.$modal.show("dashboard-delete-modal", null, null, "fixed-center",
          { dashboardId: this.id });
      }
    },
  };
</script>

<style lang="scss" scoped>
  .icon {
    &.active {
      background-color: rgb(220,220,220)
    }
  }
</style>

