<template>
  <div id="home-page" class="center-content">
    <h1>Home page</h1>
  </div>
</template>

<script>
export default {
  name: "home",
  data: function () {
    return {
    }
  }
}
</script>

<style scoped>
  #home-page {
    background: white;
    width: 100%;
    height: 100%;
  }
</style>

