<template>
  <nav v-if="pageData.current_page && pageData.total_pages > 1">
    <ul class="pagination flex flex-align-center">
      <li class="page-item" :class="{ 'disabled': pageData.first_page }">
        <a class="page-link" href="#" aria-label="Anterior"
          @click.prevent="$emit('input', pageData.prev_page)">
          <span aria-hidden="true">&laquo;</span>
          <span class="sr-only">Anterior</span>
        </a>
      </li>
      <li class="page-item clickable" v-for="page in pages">
        <a class="page-link px-5 page-style"
          :class="{ 'page-active': pageData.current_page == page }" :style="pageStyle"
          href="#" @click.prevent="$emit('input', page)">
          {{ page }}
        </a>
      </li>
      <li class="page-item" :class="{ 'disabled': pageData.last_page }">
        <a class="page-link" href="#" aria-label="Próxima"
          @click.prevent="$emit('input', pageData.next_page)">
          <span aria-hidden="true">&raquo;</span>
          <span class="sr-only">Próxima</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
  export default {
    name: "pagination",
    props: {
      pageData: {
        type: Object,
        default: () => ({}),
      },
      offset: {
        type: Number,
        default: 5,
      },
    },
    computed: {
      pages() {
        const pages = this.pageData.total_pages;
        const current = this.pageData.current_page;
        const first = Math.min(Math.max(current - this.offset, 1), pages);
        const last = Math.min(current + this.offset, pages);
        return Array.from(Array(last - first + 1).keys()).map((n) => n + first);
      },
      pageStyle() {
        return {
          '--nonactive-color': this.themeStyle.primary,
          '--nonactive-color-hover': this.themeStyle.primary2,
          '--nonactive-background': "white",
          '--nonactive-background-hover': this.themeStyle.primary,
          '--active-color': this.themeStyle.primary2,
          '--active-color-hover': this.themeStyle.primary2,
          '--active-background': this.themeStyle.primary,
          '--active-background-hover': this.themeStyle.primary,
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .page-style {
    color: var(--nonactive-color);
    background-color: var(--nonactive-background);
    &:hover {
      background-color: var(--nonactive-background-hover);
      color: var(--nonactive-color-hover);
      transition: 200ms;
    }

    &.page-active {
      color: var(--active-color);
      background-color: var(--active-background);
      &:hover {
        background-color: var(--active-background-hover);
        color: var(--active-color-hover);
        transition: 200ms;
      }
    }
  }
</style>
