<template>
  <div>
    <div class="controls flex center end">
      <div v-for="control in screenControls">
        <i v-if="control.type === 'icon'" class="control"
          :title="control.title" :class="control.icon"
          @click="handleFunctionCall(control.method)"></i>
        <p v-else-if="control.type === 'button'" class="btn btn-main" :style="buttonStyle" @click="goToComponent(control)">
          {{ control.title }}
        </p>
      </div>
    </div>
    <div id="app" v-if="themeStyle">
      <div class="side-menu">
        <side-menu />
      </div>

      <div class="app-container">
        <div class="header">
          <header-bar />
        </div>
        <div class="view" :style="{backgroundColor: themeStyle.background}">
          <router-view />
        </div>
      </div>
    </div>
    <modal />
  </div>
</template>

<script>
export default {
  el: "application",
  data: function () {
    return {
      controls: [
        {
          type: "icon",
          name: "dashboard-show",
          method: "info",
          title: "Ver informações",
          hotkey: "i",
          icon: "fas fa-info-circle",
          access: ["customer", "customer_admin"],
        },
        {
          type: "icon",
          name: "dashboard-show",
          method: "reload",
          title: "Recarregar informações",
          hotkey: "r",
          icon: "fas fa-redo",
          access: ["customer", "customer_admin"],
        },
        {
          type: "icon",
          name: "dashboard-show",
          method: "fullscreen",
          title: "Tela cheia",
          hotkey: "f",
          icon: "fas fa-expand",
          access: ["customer", "customer_admin"],
        },
        {
          type: "button",
          name: "roles-list",
          component: "roles-form",
          title: "Criar nível de acesso",
          hierarchy: "primary",
          access: ["customer", "customer_admin"],
        },
        {
          type: "button",
          name: "users-list",
          component: "users-form",
          title: "Convidar colaborador",
          hierarchy: "primary",
          access: ["customer", "customer_admin"],
        },
        {
          type: "button",
          name: "dashboard-list",
          component: "dashboards-form",
          title: "Inserir dashboard",
          hierarchy: "primary",
          access: ["customer_admin"],
        },
      ],
    }
  },
  mounted() {
    this.getUserData();
  },
  computed: {
    screenControls() {
      return this.controls.filter((control) => this.$route.name == control.name && control.access.includes(this.$store.state.userData.access))
    },
    buttonStyle() {
      return {
        '--color': "white",
        '--color-hover': "white",
        '--backgroundcolor': this.themeStyle.primary,
        '--backgroundcolor-hover': this.themeStyle.primary,
      }
    },
  },
  methods: {
    async getUserData() {
      try {
        const response = await axios.get("/api/v1/user_data");
        this.$store.commit("setUserData", response.data)
      } catch {
        console.log("Error in getting userData")
      }
    },
    reload() {
      const iframe = document.getElementById('iframe');
      iframe.src = iframe.src;
    },
    fullscreen() {
      this.$eventHub.$emit("fullscreen")
    },
    handleFunctionCall(functionName) {
      this[functionName]();
    },
    goToComponent(control) {
      this.$router.push({ name: control.component })
    },
    info(){
      const component = this.$router.app.$children[3];
      component.dashboardInfo(component.dashboard);
    },
  }
}
</script>

<style lang="scss" scoped>
  #app {
    display: flex;
    height: 100vh;
    position: relative;
  }

  .btn-main {
    color: var(--color);
    background-color: var(--backgroundcolor);
    // &:hover {
    //   background-color: var(--backgroundcolor-hover);
    //   color: var(--color-hover);
    //   transition: 200ms;
    // }
  }

  .side-menu {
    flex: 0 0 20%;
    background-color: white;
    height: 100%;
  }

  .app-container {
    flex: 0 0 80%;
    height: 100%;

    .header {
      height: 10vh;
    }
    .view {
      height: 90vh;
      padding: 0 30px;
      overflow-y: scroll;
    }
  }
  .controls {
    padding-right: 24px;
    z-index: 999;
    position: absolute;
    top: 24px;
    right: 0;
  }
  .control {
    font-size: 28px;
    border-radius: 50%;
    padding: 0 12px;
    color: rgb(110,110,110);
    &:hover {
      opacity: 0.8;
      cursor: pointer;
      transition: 200ms;
    }
  }
</style>
