<template>
  <div class="h-100">
    <div class="flex column between h-100">
      <div class="h-100">
        <div class="flex center between my-3">
          <div v-if="pagination" class="flex start center f-80">
            <search v-model="filter_params.name" class="f-50 mr-4" placeholder="Filtrar dashboards por nome"/>
            <filter-select v-model="filter_params.role_id" :options="roles" key-name="id" name="name"
              placeholder="Todos meus níveis de acesso" class="mr-4" />
            <div class="filter-star-div flex center around clickable" @click="toggleFilterBookmark">
              <i class="fa-star fs-20" :class="filter_params.bookmark ? 'fas' : 'far'"></i>
            </div>
          </div>
          <p class="f-20 m-0 gs-50 text-right pr-4">
            {{pagination.total_count}} resultados
          </p>
        </div>

        <div class="row dashboards">
          <div class="col-xs-3 f-33" v-for="dashboard in dashboards" @click="goToDashboard(dashboard)">
            <div class="flex start column dashboard clickable">
              <div class="f-75 dashboard-picture" :style="dashboardPictureStyle(dashboard)">
                <i @click.stop="toggleBookmark(dashboard)" class="clickable fa-star dashboard-bookmark fs-20" :class="bookmarkStyle(dashboard)"></i>
              </div>
              <div class="f-25 dashboard-footer flex between center">
                <div class="f-80">
                  <div class="h-50">
                    <p class="m-0 fw-600 gs-70 f-50 dashboard-name">{{dashboard.name}}</p>
                  </div>
                  <div class="h-50">
                    <p class="gs-110 m-0 f-50 dashboard-summary">
                      {{dashboard.summary || dashboard.name}}
                    </p>
                  </div>
                </div>
                <div class="f-10 text-right">
                  <i class="fas fa-edit clickable dashboard-icon" v-if="isCustomerAdmin"
                    @click.stop="dashboardEdit(dashboard)" :style="{color: themeStyle.support}"></i>
                </div>
                <div class="f-10 text-right">
                  <i class="fas fa-info-circle clickable dashboard-icon"
                    @click.stop="dashboardInfo(dashboard)" :style="{color: themeStyle.support}"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex center around h-10 mt-5">
        <pagination v-if="pagination" :page-data="pagination" @input="changePage"/>
      </div>
    </div>
  </div>
</template>

<script>
  import VueLodash from 'vue-lodash'
  import lodash from 'lodash'

  export default {
    name: "dashboard-list",
    data() {
      return {
        dashboards: [],
        pagination: null,
        filter_params: {
          name: "",
          role_id: null,
          bookmark: false,
        },
        page: 1,
        roles: []
      };
    },
    mounted() {
      this.fetchDashboards();
    },
    watch: {
      filter_params: {
        deep: true,
        handler() {
          this.page = 1;
          this.refresh();
        },
      },
    },
    methods: {
      refresh: lodash.debounce(function refresh() {
        this.fetchDashboards();
      }, 500),
      async fetchDashboards() {
        try {
          const params = {
            page: this.page,
            name: this.filter_params.name,
            role_id: this.filter_params.role_id,
            bookmark: this.filter_params.bookmark,
          }

          const response = await axios.get(`/api/v1/dashboards`, { params });
          this.roles = response.data.roles;
          this.dashboards = response.data.dashboards;
          this.pagination = response.data.pagination;
        } catch {
          console.log("Error in fetching business")
        }
      },
      async createBookmark(dashboard) {
        try {
          const params = {
            dashboard_id: dashboard.id
          }

          const response = await axios.post(`/api/v1/bookmarks`, { bookmark: params });
          if (response.data.success) {
            dashboard.bookmark_id = response.data.bookmark_id;
          } else {
            $snackbar.create("Erro ao adicionar favorito", { activeClass: "danger" });
          }
        } catch {
          $snackbar.create("Erro ao adicionar favorito", { activeClass: "danger" });
        }
      },
      async destroyBookmark(dashboard) {
        try {
          const response = await axios.delete(`/api/v1/bookmarks/${dashboard.bookmark_id}`);
          if (response.data.success) {
            dashboard.bookmark_id = null;
          } else {
            $snackbar.create("Erro ao remover favorito", { activeClass: "danger" });
          }
        } catch {
          $snackbar.create("Erro ao remover favorito", { activeClass: "danger" });
        }
      },
      toggleFilterBookmark() {
        this.filter_params.bookmark = !this.filter_params.bookmark;
      },
      async toggleBookmark(dashboard) {
        if (dashboard.bookmark_id) {
          this.destroyBookmark(dashboard);
        } else {
          this.createBookmark(dashboard);
        }
      },
      bookmarkStyle(dashboard) {
        return dashboard.bookmark_id ? "fas" : "far"
      },
      changePage(page) {
        this.page = page
        this.fetchDashboards();
      },
      goToDashboard(dashboard) {
        this.$router.push({ path: `dashboards/${dashboard.id}` })
      },
      dashboardInfo(dashboard) {
        window.$modal.show("dashboard-info-modal", null, null, "fixed-right", { dashboard });
      },
      dashboardEdit(dashboard) {
        this.$router.push({ path: `dashboards/${dashboard.id}/edit` })
      },
      dashboardPictureStyle(dashboard) {
        return {
          backgroundImage: `url(${dashboard.preview})`
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
  .fas.fa-star {
    color: #FFD700;
  }
  .far.fa-star {
    color: rgb(150,150,150);
  }
  .filter-star-div {
    padding: 4px;
    text-align: center;
    background-color: white;
    height: 40px;
    width: 40px;
    border-radius: 6px;
  }
  .dashboard-picture {
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 12px 12px 0 0;
    position: relative;

    .dashboard-bookmark {
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }

  .dashboards {
    min-height: 80%;
  }

  .dashboard {
    height: 240px;
    margin-bottom: 40px;
    border-radius: 12px 12px 12px 12px;

  }

  .dashboard-footer {
    background-color: white;
    border-radius: 0 0 12px 12px;
    padding: 15px;
  }

  .dashboard-name { font-size: 18px; }
  .dashboard-summary { font-size: 14px; }
  .dashboard-icon { font-size: 24px; }

  @media screen and (max-width: 1300px) {
    .dashboard {
      height: 200px;
      margin-bottom: 25px;
    }

    .dashboard-footer {
      padding: 12px;
    }

    .dashboard-name { font-size: 14px; }
    .dashboard-summary { font-size: 11px; }
    .dashboard-icon { font-size: 20px; }
  }

</style>
