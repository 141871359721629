<template>
  <div class="checkbox-c flex start" @click="toggle">
    <div class="f-5 mr-4">
      <i class="fas fs-24 fa-check-square text-center" v-if="value"></i>
      <i class="far fa-square fs-24 text-center" v-else></i>
    </div>
    <div class="f-90">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "checkbox",
  props: ["value"],
  data: function () {
    return {
    }
  },
  methods: {
    toggle() {
      this.value = !this.value;
      this.$emit("input", this.value)
    }
  }
}
</script>

<style scoped lang="scss">
  .checkbox-c {
    &:hover {
      cursor: pointer;
      opacity: 0.95;
    }

    i {
      color: rgb(50,50,80);
    }
  }
</style>
