<template>
  <div>
    <div class="form-container">
      <input-box v-model="item.name" placeholder="Nome do nível de acesso" title="Nível de acesso"
        :error="errors.name"/>

      <p>Escolha o ícone que melhor representa este nível de acesso</p>
      <div class="icons flex center wrap">
        <div v-for="icon in icons" class="icon clickable f-5 px-4 py-4 fs-20" :class="{ active: item.icon === icon}"
          @click="item.icon = icon">
          <i :class="icon" class="fas"></i>
        </div>
      </div>
      <p v-if="errors.icon" class="text-danger m-0 pl-3 pt-2">{{errors.icon}}</p>

      <options-list v-model="item.user_ids" header="Associar usuários" details="Escolha abaixo usuários para associar a esse nível de acesso" :items="users"/>

      <options-list v-model="item.dashboard_ids" header="Associar dashboards" details="Escolha abaixo dashboards para associar a esse nível de acesso" :items="dashboards"/>

      <div class="form-actions mt-5 flex center end">
        <router-link to="/roles">
          <p class="btn btn-secondary px-5 mr-3">Voltar</p>
        </router-link>
        <p class="btn btn-danger mx-3" v-if="roleId" @click="deleteRole">Deletar nível de acesso</p>
        <p class="btn btn-primary main" @click="save">{{saveBtnText}}</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "roles-form",
    data() {
      return {
        item: {
          name: "",
          icon: "",
          dashboard_ids: [],
          user_ids: [],
          dashboard_names: [],
          user_names: [],
        },
        icons: [],
        users: [],
        dashboards: [],
        errors: {},
      };
    },
    computed: {
      roleId() {
        return this.$route.params.id;
      },
      saveBtnText() {
        return this.roleId ? "Editar nível de acesso" : "Criar nível de acesso"
      }
    },
    mounted() {
      this.fetchConfigs();
      if (this.roleId) this.fetchRole();
    },
    methods: {
      async fetchConfigs() {
        try {
          const response = await axios.get("/api/v1/roles/new");
          this.icons = response.data.icons
          this.users = response.data.users
          this.dashboards = response.data.dashboards
        } catch {
          console.log("Error in fetching roles/new")
        }
      },
      async fetchRole() {
        try {
          const response = await axios.get(`/api/v1/roles/${this.roleId}/edit`);
          this.item = response.data.role
        } catch {
          console.log("Error in fetching roles/edit")
        }
      },
      async save() {
        try {
          if (this.roleId) {
            const response = await axios.patch(`/api/v1/roles/${this.roleId}`, { role: this.item });
            if (response.data.success) {
              this.$router.push({name: "roles-list"})
              $snackbar.create("Nível de acesso atualizado com sucesso", { activeClass: "success" });
            } else {
              this.errors = response.data.errors;
              $snackbar.create("Erro ao atualizar nível de acesso", { activeClass: "danger" });
            }
          } else {
            const response = await axios.post("/api/v1/roles", { role: this.item });
            if (response.data.success) {
              this.$router.push({name: "roles-list"})
              $snackbar.create("Nível de acesso criado com sucesso", { activeClass: "success" });
            } else {
              $snackbar.create("Erro ao criar nível de acesso", { activeClass: "danger" });
              this.errors = response.data.errors;
            }
          }
        }
        catch {
          console.log("Error in saving role")
        }
      },
      async deleteRole() {
        window.$modal.show("role-delete-modal", null, null, "fixed-center",
          { users: this.item.user_names, dashboards: this.item.dashboard_names, roleId: this.roleId });
      }
    },
  };
</script>

<style lang="scss" scoped>
  .icon {
    &.active {
      background-color: rgb(220,220,220)
    }
  }
</style>

