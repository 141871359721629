<template>
  <div class="side-menu" :style="{backgroundColor: themeStyle.primary}">
    <div class="logo flex around center clickable" @click="goToHome">
      <img :src="logoUrl" class="h-100 w-90" alt="">
    </div>

    <div class="routes">
      <div class="top-routes">
        <div v-for="item in currentUserMenuItems" class="flex center start each-item clickable mb-2 p-3"
          @click="navigateToItem(item)" :style="itemStyle">
          <i :class="item.icon" class="item-icon fs-20 f-20"></i>
          <p class="m-0 fs-16 f-60">
            {{item.name}}
          </p>
        </div>
        <div class="flex center start each-item clickable mb-2 p-3" @click="logout" :style="itemStyle">
          <i class="item-icon fs-20 f-20 fas fa-sign-out-alt"></i>
          <p class="m-0 fs-16 f-60">
            Sair
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "side-menu",
  props: [],
  data: function () {
    return {

    }
  },
  computed: {
    itemStyle() {
      return {
        '--color': this.themeStyle.primary2,
        '--color-hover': this.themeStyle.primary,
        '--backgroundcolor-hover': this.themeStyle.primary2,
      }
    },
    buttonStyle() {
      return {
        '--color': this.themeStyle.support,
        '--background': this.themeStyle.background,
      }
    },
    sideMenuItems() {
      return [
        {
          name: "Dashboards",
          icon: "fas fa-columns",
          component: "dashboard-list",
          access: ["customer", "customer_admin"],
        },
        {
          name: "Empresas",
          icon: "fas fa-building",
          component: "business-list",
          access: ["admin"],
        },
        {
          name: "Níveis de acesso",
          icon: "fas fa-key",
          component: "roles-list",
          access: ["customer_admin"],
        },
        {
          name: "Temas",
          icon: "fas fa-paint-brush",
          component: "themes-list",
          access: ["admin"],
        },
        {
          name: "Colaboradores",
          icon: "fas fa-users",
          component: "users-list",
          access: ["customer_admin"],
        },
        {
          name: "Minha conta",
          icon: "fas fa-user",
          component: "account-form",
          access: ["customer", "customer_admin"],
        },
      ]
    },
    currentUserMenuItems() {
      return this.sideMenuItems.filter(item => item.access.includes(this.$store.state.userData.access))
    },
  },
  methods: {
    async logout() {
      const response = await axios.get("/api/v1/logout");
      if (response.data.success) {
        window.location = "/"
      }
    },
    navigateToItem(item) {
      this.$router.push({ name: item.component })
    },
    goToHome() {
      this.$router.push({ name: "dashboard-list" })
    }
  }
}
</script>

<style scoped lang="scss">
  .side-menu {
    height: 100%;
    padding-top: 10px;
    background-color: white;
  }
  .logo {
    padding: 20px;
    margin: 0;
    height: 112px;
  }
  .routes {
    padding: 20px;
    height: calc(100vh - 150px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .each-item {
    color: var(--color);
    &:hover {
      background-color: var(--backgroundcolor-hover);
      color: var(--color-hover);
      transition: 200ms;
    }
  }
  .logout-btn {
    color: var(--color);
    background-color: var(--background);
  }

  @media screen and (max-width: 1300px) {
    .logo {
      height: 108px;
    }
    .routes {
      height: calc(100vh - 118px);
    }
  }
</style>
