<template>
  <modal-template @close="$emit('close')">
    <template slot="title">
      <p>Input de Iframe</p>
    </template>

    <template slot="main">
      <p>Insira, no campo abaixo, a URL embedável do seu relatório:</p>

      <input-box v-model="iframeUrl" placeholder="URL do Iframe" title="URL do Iframe"/>

      <div id="iframe-preview" v-show="valid">
        <iframe :src="iframeUrlBuild" frameborder="0" id="iframe" name="iframe">

        </iframe>
      </div>
    </template>

    <template slot="footer">
      <div class="flex center end">
        <p class="btn btn-secondary mx-2" @click="$emit('close')">Voltar</p>
        <p class="btn btn-primary mx-2" :disabled="!valid" @click="inputUrl">Confirmar e definir URL</p>
      </div>
    </template>
  </modal-template>
</template>
<script>
  export default {
    name: "dashboard-input-modal",
    data() {
      return {
        valid: false,
        iframeUrl: "",
        iframeUrlBuild: "",
      };
    },
    watch: {
      iframeUrl(newVal) {
        if (this.validURL(newVal)) {
          this.iframeUrlBuild = newVal;
          this.valid = true;
        } else {
          this.iframeUrlBuild = "";
          this.valid = false;
        }
      }
    },
    methods: {
      inputUrl() {
        this.$eventHub.$emit("input-url", this.iframeUrlBuild);
        this.$emit("close");
      },
      validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+
          '(\\?[;&a-z\\d%_.~+=-]*)?'+
          '(\\#[-a-z\\d_]*)?$','i');
        return !!pattern.test(str);
      }
    },
  };
</script>

<style scoped lang="scss">
  #iframe {
    height: 80vh;
    width: 100%;
  }
</style>

