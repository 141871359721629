<template>
  <modal-template @close="$emit('close')">
    <template slot="title">
      <p>Deletar usuário</p>
    </template>

    <template slot="main">
      <p>Esta ação irá remover {{user.first_name}} {{user.last_name}} ({{user.email}}) da plataforma.</p>

      <div v-if="currentRoles.length">
        <p>Este usuário está atualmente associado aos seguintes níveis de acesso:</p>

        <ul>
          <li v-for="role in currentRoles">{{role.name}}</li>
        </ul>
      </div>

      <p>De acordo com os termos de uso e a proteção de dados, apagar este usuário fará com que sua conta seja permanentemente deletada. Para que consiga utilizar a plataforma, deverá ser convidado e associado aos níveis de acesso novamente.</p>

      <p>Para confirmar essa operação, digite seu email no campo abaixo e clique em "Apagar mesmo assim"</p>

      <input-box v-model="email" placeholder="Seu email" title="Seu email" class="mt-5"
        :error="emailError"/>
    </template>

    <template slot="footer">
      <div class="flex center end">
        <p class="btn btn-secondary mx-2" @click="$emit('close')">Voltar</p>
        <p class="btn btn-danger mx-2" @click="deleteUser">Apagar mesmo assim</p>
      </div>
    </template>
  </modal-template>
</template>
<script>
  export default {
    name: "user-delete-modal",
    props: ["user", "userId", "currentRoles"],
    data() {
      return {
        emailError: "",
        email: "",
      };
    },
    methods: {
      async deleteUser() {
        if (this.$store.state.userData.email != this.email) {
          this.emailError = "E-mail incorreto";
          return;
        } else {
          this.emailError = "";
        }

        try {
          const response = await axios.delete(`/api/v1/users/${this.userId}`);
          if (response.data.success) {
            this.$emit("close");
            this.$router.push({name: "users-list"})
            $snackbar.create("Usuário removido com sucesso", { activeClass: "success" });
          } else {
            $snackbar.create("Erro ao remover usuário", { activeClass: "danger" });
          }
        } catch {
          $snackbar.create("Erro ao remover usuário", { activeClass: "danger" });
        }
      }
    },
  };
</script>

