<template>
  <div>
    <div class="controls flex end">
      <router-link to="/themes/new">
        <p class="btn btn-primary">Criar novo tema</p>
      </router-link>
    </div>
    <div class="list">
      <div class="list-headers flex center">
        <p class="f-20">Nome</p>
        <p class="f-80">Esquema de cores</p>
      </div>
      <div class="dashboard list-item clickable flex center py-4" v-for="theme in themes"
        @click="gotoThemeEdit(theme)">
        <p class="m-0 f-20">{{theme.name}}</p>
        <div class="f-80 flex center start">
          <div class="color-square mr-3" :style="{backgroundColor: `#${theme.primary}`}"></div>
          <div class="color-square mr-3" :style="{backgroundColor: `#${theme.primary2}`}"></div>
          <div class="color-square mr-3" :style="{backgroundColor: `#${theme.secondary}`}"></div>
          <div class="color-square mr-3" :style="{backgroundColor: `#${theme.secondary2}`}"></div>
          <div class="color-square mr-3" :style="{backgroundColor: `#${theme.background}`}"></div>
          <div class="color-square mr-3" :style="{backgroundColor: `#${theme.support}`}"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "themes-list",
    data() {
      return {
        themes: [],
      };
    },
    mounted() {
      this.fetchThemes();
    },
    methods: {
      async fetchThemes() {
        try {
          const response = await axios.get("/api/v1/admin/themes");
          this.themes = response.data.themes
        } catch {
          console.log("Error in fetching themes")
        }
      },
      gotoThemeEdit(theme) {
        this.$router.push({ path: `themes/${theme.id}/edit` })
      },
    },
  };
</script>

<style lang="scss" scoped>
  .color-square {
    height: 40px;
    width: 120px;
  }
</style>
