<template>
  <div class="search-div">
    <input @input="emit" type="text" :value="value" class="search-filter"
      :placeholder="placeholder">
    <i class="fas fa-search search-icon"></i>
  </div>
</template>

<script>
export default {
  name: "search",
  props: ["value", "placeholder"],
  data: function () {
    return {
    }
  },
  methods: {
    emit(ev) {
      this.$emit("input", ev.target.value)
    }
  }
}
</script>

<style scoped lang="scss">
  .search-div {
    position: relative;
    height: 40px;
  }
  .search-filter {
    background-position: 10px 12px; /* Position the search icon */
    background-repeat: no-repeat; /* Do not repeat the icon image */
    font-size: 16px; /* Increase font-size */
    padding: 12px 20px 12px 50px; /* Add some padding */
    border: 1px solid #ddd; /* Add a grey border */
    height: 100%;
    width: 100%;
    border-radius: 4px;
  }
  .search-filter::placeholder {
    font-size: 14px;
    color: rgb(160,160,160);
  }
  .search-icon {
    position: absolute;
    top: 10px;
    font-size: 20px;
    left: 10px;
    color: rgb(120,120,120);
  }
</style>
