<template>
  <div class="textarea-div my-3">
    <textarea ref="textarea" class="textarea" :class="{active: active, error: error}"
      :style="style"
      :value="value" @input="$emit('input', $event.target.value)"
      :placeholder="placeholder" @focus="active = true" @blur = "active = false"
      :disabled="disabled"/>
    <p v-if="error" class="text-danger error-content m-0">{{error}}</p>
  </div>
</template>

<script>
export default {
  name: "text-area",
  props: ["value", "placeholder", "title", "error", "disabled", "height"],
  data: function () {
    return {
      active: false,
    }
  },
  computed: {
    style() {
      return {
        height: this.height
      }
    }
  }
}
</script>

<style scoped lang="scss">
  textarea {
    border: 1px solid rgba(100,100,100,1);
    border-radius: 3px;
    height: 40px;
    width: 100%;
    padding: 12px 20px;
    resize: none;
    box-sizing: border-box;

    &.error {
      border: 1px solid #D9534F;
      box-shadow: 2px 2px #ffc4c2;
    }
  }
</style>
