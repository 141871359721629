<template>
  <modal-template @close="$emit('close')">
    <template slot="title">
      <p>Deletar dashboard</p>
    </template>

    <template slot="main">
      <p>Ao este dashboard, ele será removido da listagem e não poderá ser recuperado. Se você quiser visualizar esse dashboard novamente, terá que recriá-lo. Tem certeza que deseja continuar?</p>
    </template>

    <template slot="footer">
      <div class="flex center end">
        <p class="btn btn-secondary mx-2" @click="$emit('close')">Voltar</p>
        <p class="btn btn-danger mx-2" @click="deleteDashboard">Apagar mesmo assim</p>
      </div>
    </template>
  </modal-template>
</template>
<script>
  export default {
    name: "dashboard-delete-modal",
    props: ["dashboardId"],
    data() {
      return {};
    },
    methods: {
      async deleteDashboard() {
        try {
          const response = await axios.delete(`/api/v1/dashboards/${this.dashboardId}`);
          if (response.data.success) {
            this.$emit("close");
            this.$router.push({name: "dashboard-list"})
            $snackbar.create("Dashboard deletado com sucesso", { activeClass: "success" });
          } else {
            $snackbar.create("Erro ao deletar dashboard", { activeClass: "danger" });
          }
        } catch {
          $snackbar.create("Erro ao deletar dashboard", { activeClass: "danger" });
        }
      }
    },
  };
</script>

