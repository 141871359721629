<template>
  <div class="header-bar" :style="{backgroundColor: themeStyle.background}">
    <breadcrumb class="f-60"/>
  </div>
</template>

<script>
export default {
  name: "header-bar",
  props: [],
  data: function () {
    return {}
  },
}
</script>

<style scoped lang="scss">
  .header-bar {
    height: 100%;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
</style>
