<template>
  <modal-template @close="$emit('close')" no-footer>
    <template slot="title">
      <div class="flex between center w-100">
        <div class="f-80">
          <p class="bold fs-18">{{dashboard.name}}</p>
        </div>
        <div class="f-20 text-right">
          <i class="fas fa-times fs-24 clickable" @click="$emit('close')"></i>
        </div>
      </div>
    </template>

    <template slot="main" style="height: 80vh;">
      <div class="h-100 scrollable pr-5">
        <p class="mb-5 gs-30 bold">{{dashboard.summary}}</p>

        <p class="m-0 gs-70" v-html="dashboard.description"></p>

        <p class="btn btn-primary w-100 mt-5" v-if="isCustomerAdmin"
          @click="goToDashboardViews">
          <i class="fa fa-eye fs-24 mr-3"></i>
          Ver histórico de visualizações
        </p>

        <p v-if="dashboard.visible_to_users && dashboard.visible_to_users.length"
          class="info-text p-4 mt-5">
          <i class="fas fa-exclamation-triangle fs-20 mr-4"></i>
          Os seguintes usuários podem ver este dashboard:
          <ul class="m-0 mt-2">
            <li v-for="user in dashboard.visible_to_users">
              {{user}}
            </li>
          </ul>
          <br>
          (Esta informação é visível apenas para os administradores do projeto)
        </p>
      </div>
    </template>
  </modal-template>
</template>
<script>
  export default {
    name: "dashboard-info-modal",
    props: ["dashboard"],
    data() {
      return {};
    },
    methods: {
      goToDashboardViews() {
        this.$router.push({ path: `/views/dashboard/${this.dashboard.id}`});
        this.$emit("close");
      }
    },
  };
</script>

