<template>
  <modal-template @close="$emit('close')">
    <template slot="title">
      <p>Deletar tema</p>
    </template>

    <template slot="main">
      <div v-if="businessNames.length">
        <p>Este tema está associado às seguintes empresas:</p>

        <ul>
          <li v-for="name in businessNames">{{name}}</li>
        </ul>

        <p>Não é possível deletar um tema que esteja associado a alguma empresa. Você receberá um erro.</p>
      </div>

      <div v-else>
        <p>Tem certeza que deseja apagar esse tema?</p>
      </div>
    </template>

    <template slot="footer">
      <div class="flex center end">
        <p class="btn btn-secondary mx-2" @click="$emit('close')">Voltar</p>
        <p class="btn btn-danger mx-2" @click="deleteTheme">Apagar</p>
      </div>
    </template>
  </modal-template>
</template>
<script>
  export default {
    name: "theme-delete-modal",
    props: ["themeId", "businessNames"],
    data() {
      return {};
    },
    methods: {
      async deleteTheme() {
        try {
          const response = await axios.delete(`/api/v1/admin/themes/${this.themeId}`);
          if (response.data.success) {
            this.$emit("close");
            this.$router.push({name: "themes-list"})
            $snackbar.create("Tema deletado com sucesso", { activeClass: "success" });
          } else {
            $snackbar.create("Erro ao deletar tema", { activeClass: "danger" });
          }
        } catch {
          console.log("Error in deleting theme")
        }
      }
    },
  };
</script>

