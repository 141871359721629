<template>
  <div>
    <div class="controls flex end">
      <router-link to="/reports/new">
        <p class="btn btn-primary">Inserir Report</p>
      </router-link>
    </div>
    <div class="list">
      <div class="list-headers flex center">
        <p class="f-40">Nome</p>
        <p class="f-20 text-center">Contagem de dashboards</p>
      </div>
      <div class="dashboard list-item clickable flex center py-4" v-for="report in reports"
        @click="gotoReportEdit(report)">
        <p class="m-0 f-40">{{report.name}}</p>
        <p class="m-0 f-20 text-center">{{report.dashboard_count}}</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "report-list",
    data() {
      return {
        reports: [],
      };
    },
    mounted() {
      this.fetchReports();
    },
    methods: {
      async fetchReports() {
        try {
          const response = await axios.get("/api/v1/admin/reports");
          this.reports = response.data.reports
        } catch {
          console.log("Error in fetching reports")
        }
      },
      gotoReportEdit(report) {
        this.$router.push({ path: `reports/${report.id}/edit` })
      },
    },
  };
</script>
