<template>
  <div>
    <div class="form-container">
      <input-box v-model="item.name" placeholder="Nome da empresa" title="Empresa"/>
      <input-box v-model="item.cnpj" placeholder="CNPJ" title="Empresa" mask="99.999.999/9999-99"/>
      <input-box v-model="item.main_contact_email" :disabled="id" placeholder="Email do contato principal" title="Empresa"/>

      <div class="flex between center">
        <div class="f-48">
          <input-box v-model="item.main_contact_first_name" :disabled="id" placeholder="Nome do contato principal" title="Nome do contato principal" />
        </div>
        <div class="f-48">
          <input-box v-model="item.main_contact_last_name" :disabled="id"placeholder="Sobrenome do contato principal" title="Sobrenome do contato principal" />
        </div>
      </div>

      <input-box v-model="item.main_contact_phone_number" :disabled="id" placeholder="Telefone do contato principal" title="Telefone do contato principal"
      mask="(99) 99999-9999"/>

      <input-box v-model="item.user_count" placeholder="Limite de usuários" title="Limite de usuários"/>

      <text-area v-model="item.service_description" placeholder="Descrição do serviço" title="Empresa"height="300px"/>

      <input-box v-model="item.pricing" placeholder="Ticket mensal" title="Ticket mensal" :mask="currencyMask"/>

      <input-box v-model="item.subdomain" placeholder="Subdomínio" title="Subdomínio"/>

      <select-box v-model="item.theme_id" :options="themes"
        label="name" placeholder="Tema" key-name="id" class="mb-5" />

      <div class="f-80 flex center start mb-5" v-if="selectedTheme" style="height: 50px;">
        <div class="w-15 h-100 mr-3" :style="{backgroundColor: `#${selectedTheme.primary}`}"></div>
        <div class="w-15 h-100 mr-3" :style="{backgroundColor: `#${selectedTheme.primary2}`}"></div>
        <div class="w-15 h-100 mr-3" :style="{backgroundColor: `#${selectedTheme.secondary}`}"></div>
        <div class="w-15 h-100 mr-3" :style="{backgroundColor: `#${selectedTheme.secondary2}`}"></div>
        <div class="w-15 h-100 mr-3" :style="{backgroundColor: `#${selectedTheme.background}`}"></div>
        <div class="w-15 h-100 mr-3" :style="{backgroundColor: `#${selectedTheme.support}`}"></div>
      </div>

      <h3>Logo atual</h3>
      <div class="w-100">
        <div v-if="item.logo_pic">
          <img :src="item.logo_pic" alt="">
        </div>
        <p v-else>Nenhum logo cadastrado</p>
      </div>

      <p class="my-5">Prévia de novo logo:</p><input type="file" ref="inputFile" @change="uploadFile">
      <img ref="logoImg" :src="item.preview_logo" alt="" class="mt-5">

      <div class="form-actions flex center end my-5">
        <router-link to="/businesses">
          <p class="btn btn-secondary px-5 mr-3">Voltar</p>
        </router-link>
        <p class="btn btn-primary main" @click="save">{{saveBtnText}}</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "business-form",
    props: ["id"],
    data() {
      return {
        item: {
          id: null,
          name: null,
          cnpj: null,
          subdomain: null,
          main_contact_email: null,
          main_contact_first_name: null,
          main_contact_last_name: null,
          main_contact_phone_number: null,
          service_description: null,
          pricing: null,
          user_count: null,
          theme_id: null,
          logo: null,
        },
        themes: [],
      };
    },
    mounted() {
      this.fetchConfigs();
      if (this.id) {
        this.fetchBusiness();
      }
    },
    computed: {
      currencyMask() {
        return {
          alias: "currency",
          prefix: "R$ ",
          rightAlign: 0,
        }
      },
      saveBtnText() {
        if (this.id) {
          return "Atualizar empresa"
        } else {
          return "Criar empresa"
        }
      },
      selectedTheme() {
        return this.themes.find((theme) => theme.id == this.item.theme_id)
      }
    },
    methods: {
      async fetchConfigs() {
        try {
          const response = await axios.get(`/api/v1/admin/businesses/new`);
          this.themes = response.data.themes
        } catch {
          console.log("Error in fetching business/new")
        }
      },
      async fetchBusiness() {
        try {
          const response = await axios.get(`/api/v1/admin/businesses/${this.id}/edit`);
          this.item = response.data.business
        } catch {
          console.log("Error in fetching business/edit")
        }
      },
      async save() {
        let formData = new FormData()

        formData.set('name', this.item.name)
        formData.set('cnpj', this.item.cnpj)
        formData.set('subdomain', this.item.subdomain)
        formData.set('main_contact_email', this.item.main_contact_email)
        formData.set('main_contact_first_name', this.item.main_contact_first_name)
        formData.set('main_contact_last_name', this.item.main_contact_last_name)
        formData.set('main_contact_phone_number', this.item.main_contact_phone_number)
        formData.set('service_description', this.item.service_description)
        formData.set('pricing', this.item.pricing)
        formData.set('user_count', this.item.user_count)
        formData.set('theme_id', this.item.theme_id)
        formData.set('logo', this.item.logo)
        formData.append('logo', this.item.logo)

        const request = await axios({
          method: this.id ? "patch" : "post",
          url: `/api/v1/admin/businesses/${this.id ? this.id : ''}`,
          data: formData,
          headers: {'Content-Type': 'multipart/form-data' }
        })

        try {
          const response = await request;
          if (response.data.success) {
            this.$router.push({name: "business-list"})
            $snackbar.create(`Empresa ${this.id ? "atualizada" : "criada"} com sucesso`, { activeClass: "success" });
          } else {
            $snackbar.create(`Erro ao ${this.id ? "Atualizar" : "criar"} empresa`, { activeClass: "danger" });
          }
        } catch {
          console.log("Error in saving business")
        }
      },
      uploadFile() {
        this.item.logo = this.$refs.inputFile.files[0];

        this.$refs.logoImg.src = window.URL.createObjectURL(this.item.logo)
      },
    },
  };
</script>

<style scoped>
  .color-square {
    height: 40px;
    width: 120px;
  }
</style>
