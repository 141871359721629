<template>
  <div>
    <div class="flex center end">
      <router-link :to="`/businesses/${this.id}/dashboards/new`">
        <p class="btn btn-primary">Inserir dashboard</p>
      </router-link>
    </div>
    <div class="list">
      <div v-if="dashboards.length">
        <div class="dashboard list-item clickable flex start center" v-for="dashboard in dashboards"
          @click="goToDashboardShow(dashboard)">
          <p class="m-0 f-70">{{dashboard.name}}</p>
          <p class="m-0 btn btn-primary f-10 mr-4" @click.stop="goToDashboardEdit(dashboard)">Editar</p>
          <p class="m-0 btn btn-danger f-10" @click.stop="deleteDashboard(dashboard)">Excluir</p>
        </div>
      </div>
      <div v-else>
        <p class="m-5 p-5 warning-text">Nenhum dashboard cadastrado.</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "business-dashboard-list",
    props: ["id"],
    data() {
      return {
        dashboards: [],
      };
    },
    mounted() {
      this.fetchDashboards();
    },
    methods: {
      async fetchDashboards() {
        try {
          const response = await axios.get(`/api/v1/admin/businesses/${this.id}/dashboards`);
          this.dashboards = response.data.dashboards
        } catch {
          console.log("Error in fetching business")
        }
      },
      goToDashboardShow(dashboard) {
        this.$router.push({ path: `/dashboards/${dashboard.id}` })
      },
      goToDashboardEdit(dashboard) {
        this.$router.push({ path: `/businesses/${this.id}/dashboards/${dashboard.id}/edit` })
      },
      async deleteDashboard(dashboard) {
        const proceed = confirm(`Tem certeza que deseja excluir o dashboard ${dashboard.name}?`)

        if (proceed) {
          try {
            const response = await axios.delete(`/api/v1/admin/businesses/${this.id}/dashboards/${dashboard.id}`);
            if (response.data.success) {
              $snackbar.create("Dashboard removido com sucesso", { activeClass: "success" });
              this.dashboards = this.dashboards.filter(dash => dash.id !== dashboard.id)
            } else {
              $snackbar.create("Erro ao remover dashboard", { activeClass: "danger" });
            }
          } catch {
            console.log("Error in deleting dashboard")
          }
        }
      }
    },
  };
</script>

