<template>
  <div>
    <div class="form-container">
      <input-box v-model="item.name" placeholder="Nome do Tema" title="Tema"
        :error="errors.name && errors.name[0]"/>

      <p class="bold">Esquema de cores</p>
      <div class="flex start center my-4">
        <p class="f-20 m-0">Cor primária</p>
        <div class="f-20">
          <input-box v-model="item.primary" title="Tema" :no-margin="true" />
        </div>
        <div class="f-10"></div>
        <div class="f-40 each-color" :style="{backgroundColor: `#${item.primary}`}"></div>
      </div>

      <div class="flex start center my-4">
        <p class="f-20 m-0">Cor primária 2</p>
        <div class="f-20">
          <input-box v-model="item.primary2" title="Tema" :no-margin="true" />
        </div>
        <div class="f-10"></div>
        <div class="f-40 each-color" :style="{backgroundColor: `#${item.primary2}`}"></div>
      </div>

      <div class="flex start center my-4">
        <p class="f-20 m-0">Cor secundária</p>
        <div class="f-20">
          <input-box v-model="item.secondary" title="Tema" :no-margin="true" />
        </div>
        <div class="f-10"></div>
        <div class="f-40 each-color" :style="{backgroundColor: `#${item.secondary}`}"></div>
      </div>

      <div class="flex start center my-4">
        <p class="f-20 m-0">Cor secundária 2</p>
        <div class="f-20">
          <input-box v-model="item.secondary2" title="Tema" :no-margin="true" />
        </div>
        <div class="f-10"></div>
        <div class="f-40 each-color" :style="{backgroundColor: `#${item.secondary2}`}"></div>
      </div>

      <div class="flex start center my-4">
        <p class="f-20 m-0">Cor de fundo</p>
        <div class="f-20">
          <input-box v-model="item.background" title="Tema" :no-margin="true" />
        </div>
        <div class="f-10"></div>
        <div class="f-40 each-color" :style="{backgroundColor: `#${item.background}`}"></div>
      </div>

      <div class="flex start center my-4">
        <p class="f-20 m-0">Cor suporte</p>
        <div class="f-20">
          <input-box v-model="item.support" title="Tema" :no-margin="true" />
        </div>
        <div class="f-10"></div>
        <div class="f-40 each-color" :style="{backgroundColor: `#${item.support}`}"></div>
      </div>

      <p v-if="colorErrors" class="danger-text p-5">Preencha todas as cores</p>

      <div class="form-actions mt-5 flex center end">
        <router-link to="/themes">
          <p class="btn btn-secondary px-5 mr-3">Voltar</p>
        </router-link>
        <p class="btn btn-danger mx-3" v-if="themeId" @click="deleteTheme">Deletar tema</p>
        <p class="btn btn-primary main" @click="save">{{saveBtnText}}</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "themes-form",
    data() {
      return {
        item: {
          name: "",
          primary: "",
          primary2: "",
          secondary: "",
          secondary2: "",
          background: "",
          support: "",
        },
        errors: {},
      };
    },
    computed: {
      themeId() {
        return this.$route.params.id;
      },
      saveBtnText() {
        return this.themeId ? "Editar Tema" : "Criar Tema"
      },
      colorErrors() {
        return this.errors && (this.errors.primary || this.errors.primary2 ||
          this.errors.secondary || this.errors.secondary || this.errors.background ||
          this.errors.support)
      }
    },
    mounted() {
      if (this.themeId) this.fetchTheme();
    },
    methods: {
      async fetchTheme() {
        try {
          const response = await axios.get(`/api/v1/admin/themes/${this.themeId}/edit`);
          this.item = response.data.theme
        } catch {
          console.log("Error in fetching themes/edit")
        }
      },
      async save() {
        try {
          if (this.themeId) {
            const response = await axios.patch(`/api/v1/admin/themes/${this.themeId}`, { theme: this.item });
            if (response.data.success) {
              this.$router.push({name: "themes-list"})
              $snackbar.create("Tema atualizado com sucesso", { activeClass: "success" });
            } else {
              this.errors = response.data.errors;
              $snackbar.create("Erro ao atualizar tema", { activeClass: "danger" });
            }
          } else {
            const response = await axios.post("/api/v1/admin/themes", { theme: this.item });
            if (response.data.success) {
              this.$router.push({name: "themes-list"})
              $snackbar.create("Tema criado com sucesso", { activeClass: "success" });
            } else {
              $snackbar.create("Erro ao criar tema", { activeClass: "danger" });
              this.errors = response.data.errors;
            }
          }
        }
        catch {
          console.log("Error in saving theme")
        }
      },
      async deleteTheme() {
        window.$modal.show("theme-delete-modal", null, null, "fixed-center",
          { businessNames: this.item.business_names, themeId: this.themeId });
      }
    },
  };
</script>

<style lang="scss" scoped>
  .icon {
    &.active {
      background-color: rgb(220,220,220)
    }
  }

  .each-color {
    height: 40px;
    border-radius: 4px;
    border: 1px solid rgb(140,140,140);
  }
</style>

