<template>
  <div class="modal-template" :class="[{ 'wide': wide }, type]">
    <div class="modal-template-info">
      <div v-if="$slots.title" class="modal-template-header">
        <div class="modal-template-title">
          <slot name="title" />
        </div>
        <button v-if="!noCloseButton" type="button" class="modal-template-close"
          @click="$emit('close')">
        </button>
      </div>
      <aside v-if="$slots['left-bar']" class="modal-template-leftbar">
        <slot name="left-bar" />
      </aside>
      <slot name="main" />
    </div>
    <div v-if="!noFooter" class="modal-template-footer">
      <slot name="footer" />
      <div v-if="$slots.actions" class="modal-template-actions">
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "modal-template",
    props: {
      noFooter: {
        type: Boolean,
        default: false,
      },
      wide: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: "",
      },
      noCloseButton: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .modal-template {
    .modal-template-leftbar {
      box-shadow: inset -1px 0 0 0 rgb(50,50,50);
      padding-top: 16px;
      width: 64px;
    }
    .modal-template-info {
      padding: 32px;
      width: 100%;
    }
    .modal-template-header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
    }
    .modal-template-title {
      align-items: center;
      color: rgb(30,30,30);
      display: flex;
      flex-grow: 1;
      font-size: 20px;
      font-weight: 700;
    }
    .modal-template-close {
      background: transparent;
      border: 0;
      cursor: pointer;
      float: right;
      font-size: 21px;
      font-weight: bold;
      height: 24px;
      opacity: 0.54;
      padding: 0;
      text-shadow: 0 1px 0 white;
      width: 24px;
      &:hover {
        opacity: 1;
      }
    }
    .modal-template-footer {
      background: linear-gradient(to bottom, white-wizard, white 100px);
      border-radius: 0 0 8px 8px;
      border-top: solid rgb(230,230,230) 1px;
      margin-bottom: 12px;
      padding: 32px;
    }
    .modal-template-actions {
      border-radius: 0 0 2px 2px;
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;
    }
    &.wide {
      height: 100%;
      .modal-template-info {
        display: flex;
        height: 100%;
        padding: 0;
      }
      .modal-template-footer {
        padding: 0;
      }
    }
    &.compact-type {
      .modal-template-info {
        padding: 32px 32px 8px;
      }
      .modal-template-footer {
        border-top: none;
        margin-bottom: 0;
        padding: 8px 24px 24px;
        .modal-template-actions {
          justify-content: space-between;
          margin-top: 0;
        }
      }
    }
  }
</style>
