<template>
  <div>
    <div class="form-container">
      <h3>Identificação do Report</h3>

      <input-box v-model="item.name" placeholder="Nome" title="Nome"
        :error="errors.name"/>

      <input-box v-model="item.summary" placeholder="Resumo" title="Resumo"
        :error="errors.summary"/>

      <text-area v-model="item.description" placeholder="Descrição" title="Empresa"height="300px"/>

      <h3>URL do Iframe</h3>
      <input-box v-model="item.iframe_url" placeholder="URL do Iframe" title="URL do Iframe"
        :error="errors.iframe_url"/>

      <div class="spacer-m"></div>

      <h3>Prévia</h3>

      <div class="w-100">
        <div v-if="item.preview_pic">
          <img :src="item.preview_pic" alt="" class="w-100 img-fit">
        </div>
        <p v-else>Não há uma prévia para esse dashboard</p>
      </div>

      <p class="my-5">Nova prévia:</p><input type="file" ref="inputFile" @change="uploadFile">
      <img ref="previewImg" :src="item.preview_pic" alt="" class="w-100 img-fit mt-5">

      <div class="form-actions mt-5 flex center end">
        <router-link :to="`/businesses/${this.businessId}/panel`">
          <p class="btn btn-secondary px-5 mr-3">Voltar</p>
        </router-link>
        <p class="btn btn-primary main" @click="save">{{saveBtnText}}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  export default {
    name: "business-dashboards-form",
    components: { Multiselect },
    data() {
      return {
        item: {
          name: "",
          summary: "",
          description: "",
          iframe_url: "",
          preview: null,
          preview_pic: "",
        },
        errors: {},
        reports: [],
      };
    },
    computed: {
      id() {
        return this.$route.params.id;
      },
      businessId() {
        return this.$route.params.business_id;
      },
      saveBtnText() {
        return this.id ? "Atualizar dashboard" : "Inserir dashboard"
      }
    },
    mounted() {
      this.fetchConfigs();
      if (this.id) this.fetchDashboard();
    },
    methods: {
      uploadFile() {
        this.item.preview = this.$refs.inputFile.files[0];

        this.$refs.previewImg.src = window.URL.createObjectURL(this.item.preview)
      },
      async fetchConfigs() {
        try {
          const response = await axios.get(`/api/v1/admin/reports`);
          this.reports = response.data.reports;
        } catch {
          console.log("Error in fetching dashboards/new")
        }
      },
      async fetchDashboard() {
        try {
          const response = await axios.get(`/api/v1/admin/businesses/${this.businessId}/dashboards/${this.id}/edit`);
          this.item = response.data.dashboard
        } catch {
          console.log("Error in fetching dashboards/edit")
        }
      },
      async save() {
        if (!this.item.preview && !this.item.preview_pic) {
          $snackbar.create(`Não é possível persistir um dashboard sem prévia`, { activeClass: "danger" });
          return;
        }

        let formData = new FormData()

        formData.set('name', this.item.name)
        formData.set('summary', this.item.summary)
        formData.set('description', this.item.description)
        formData.set('iframe_url', this.item.iframe_url)
        formData.append('preview', this.item.preview)

        const request = await axios({
          method: this.id ? "patch" : "post",
          url: `/api/v1/admin/businesses/${this.businessId}/dashboards/${this.id ? this.id : ''}`,
          data: formData,
          headers: {'Content-Type': 'multipart/form-data' }
        })

        try {
          const response = await request;
          if (response.data.success) {
            this.$router.push({path: `/businesses/${this.businessId}/panel?tab=dashboards`})
            $snackbar.create(`Dashboard ${this.id ? "atualizado" : "inserido"} com sucesso`, { activeClass: "success" });
          } else {
            this.errors = response.data.errors;
            $snackbar.create(`Erro ao ${this.id ? "atualizado" : "inserido"} dashboard`, { activeClass: "danger" });
          }
      }
        catch {
          console.log("Error in saving role")
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .icon {
    &.active {
      background-color: rgb(220,220,220)
    }
  }
</style>

