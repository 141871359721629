<template>
  <modal-template @close="$emit('close')">
    <template slot="title">
      <p>Deletar nível de acesso</p>
    </template>

    <template slot="main">
      <div v-if="users.length">
        <p>Este nível de acesso está associado aos seguintes usuários:</p>

        <ul>
          <li v-for="user in users">{{user}}</li>
        </ul>
      </div>

      <div v-if="dashboards.length">
        <p>Este nível de acesso está associado aos seguintes dashboards:</p>

        <ul>
          <li v-for="dashboard in dashboards">{{dashboard}}</li>
        </ul>
      </div>

      <p>Ao apagar este nível de acesso, todas essas associações serão excluídas. Não será possível recuperar o nível de acesso. Tem certeza que deseja continuar?</p>
    </template>

    <template slot="footer">
      <div class="flex center end">
        <p class="btn btn-secondary mx-2" @click="$emit('close')">Voltar</p>
        <p class="btn btn-danger mx-2" @click="deleteRole">Apagar mesmo assim</p>
      </div>
    </template>
  </modal-template>
</template>
<script>
  export default {
    name: "role-delete-modal",
    props: ["users", "dashboards", "roleId"],
    data() {
      return {};
    },
    methods: {
      async deleteRole() {
        try {
          const response = await axios.delete(`/api/v1/roles/${this.roleId}`);
          if (response.data.success) {
            this.$emit("close");
            this.$router.push({name: "roles-list"})
            $snackbar.create("Nível de acesso deletado com sucesso", { activeClass: "success" });
          } else {
            $snackbar.create("Erro ao deletar nível de acesso", { activeClass: "danger" });
          }
        } catch {
          console.log("Error in deleting role")
        }
      }
    },
  };
</script>

