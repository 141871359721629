<template>
  <div>
    <div class="form-container">
      <p class="danger-text p-5" v-if="userLimitReachedCondition">Seu projeto atingiu o número máximo de usuários e, portanto, você não conseguirá convidar novos usuários. Se desejar aumentar o limite de usuários, entre em contato com a Master BI.</p>

      <p class="danger-text p-5" v-if="errors.must_have_at_least_one_admin">Você não pode remover o acesso de administrador desse membro, pois o projeto ficaria sem nenhum administrador.</p>

      <div v-if="!userId">
        <div class="flex center between">
          <div class="f-48">
            <input-box v-model="item.first_name" :disabled="userLimitReachedCondition"
              placeholder="Primeiro nome" title="Primeiro nome"
              :error="errors.user.first_name"/>
          </div>
          <div class="f-48">
            <input-box v-model="item.last_name" :disabled="userLimitReachedCondition"
              placeholder="Sobrenome" title="Sobrenome" :error="errors.user.last_name"/>
          </div>
        </div>

        <input-box v-model="item.email" class="mt-2" :disabled="userLimitReachedCondition"
          placeholder="E-mail" title="E-mail" :error="errors.user.email"/>
      </div>
      <div v-else>
        <p class="fs-20 mb-5"><span class="bold mr-3">Edição de usuário:</span> {{item.first_name}} {{item.last_name}} <span class="gs-110 fs-16 ml-3">{{(item.email)}}</span></p>
      </div>

      <div class="mt-2"></div>

      <options-list v-model="item.role_ids" header="Associar níveis de acesso" details="Escolha abaixo os níveis de acesso que você gostaria de associar à esse usuário" :items="roles"/>

      <div :class="{ 'warning-reminder': item.customer_admin}" class="mt-4 p-4">
        <checkbox v-model="item.customer_admin">
          <p class="m-0 mb-2 bold gs-30 unselectable">Conceder acesso de administrador</p>
          <p class="m-0 gs-90 unselectable">Ao selecionar essa opção, você estará dando a esse usuário o poder de gerenciar usuários, níveis de acesso e dashboards</p>
        </checkbox>
      </div>

      <p v-if="item.customer_admin" class="info-text p-4 mt-4">
        <i class="fas fa-exclamation-triangle fs-20 mr-4"></i>
        Como administrador, esse usuário poderá visualizar todos os dashboards do projeto
      </p>

      <p v-else-if="dashboardsSelected.length" class="info-text p-4 mt-4">
        <i class="fas fa-exclamation-triangle fs-20 mr-4"></i>
        Esses níveis de acesso permitem que o usuário visualize os seguintes dashboards:
        <ul class="m-0 mt-2">
          <li v-for="dashboard in dashboardsSelected">
            {{dashboard}}
          </li>
        </ul>
      </p>

      <div class="form-actions mt-5 flex center end">
        <router-link to="/users">
          <p class="btn btn-secondary px-5 mr-3">Voltar</p>
        </router-link>
        <p class="btn btn-danger mx-3" v-if="userId" @click="deleteUser">Desativar usuário</p>
        <p class="btn btn-primary main" :class="{ disabled: userLimitReachedCondition }" @click="save">{{saveBtnText}}</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "users-form",
    data() {
      return {
        item: {
          first_name: "",
          last_name: "",
          email: "",
          role_ids: [],
          admin: false,
        },
        roles: [],
        errors: {
          user: {},
          main_contact_must_be_admin: false,
        },
        userLimitReached: false,
      };
    },
    computed: {
      userId() {
        return this.$route.params.id;
      },
      saveBtnText() {
        return this.userId ? "Atualizar informações" : "Convidar usuário"
      },
      currentRoles() {
        return this.roles.filter((role) => this.item.role_ids.includes(role.id))
      },
      dashboardsSelected() {
        return this.roles.filter((role) => this.item.role_ids.includes(role.id)).map((role) => role.dashboards).flat().filter((value, index, self) => self.indexOf(value) === index).sort()
      },
      userLimitReachedCondition() {
        return !this.userId && this.userLimitReached
      }
    },
    mounted() {
      this.fetchConfigs();
      if (this.userId) this.fetchUser();
    },
    methods: {
      async fetchConfigs() {
        try {
          const response = await axios.get("/api/v1/users/new");
          this.roles = response.data.roles
          this.userLimitReached = response.data.user_limit_reached;
        } catch {
          console.log("Error in fetching users/new")
        }
      },
      async fetchUser() {
        try {
          const response = await axios.get(`/api/v1/users/${this.userId}/edit`);
          this.item = response.data.user
        } catch {
          console.log("Error in fetching users/edit")
        }
      },
      async save() {
        if (this.userLimitReachedCondition) {
          $snackbar.create("Você atingiu o limite de usuários no projeto. Se deseja aumentar o limite de usuários, entre em contato com a Master BI", { activeClass: "danger" });
          return;
        }
        try {
          if (this.userId) {
            const response = await axios.patch(`/api/v1/users/${this.userId}`, { user: this.item });
            if (response.data.success) {
              this.$router.push({name: "users-list"})
              $snackbar.create("Usuário atualizado com sucesso", { activeClass: "success" });
            } else {
              this.errors = response.data.errors;
              $snackbar.create("Erro ao atualizar usuário", { activeClass: "danger" });
            }
          } else {
            const response = await axios.post("/api/v1/users", { user: this.item });
            if (response.data.success) {
              this.$router.push({name: "users-list"})
              $snackbar.create("Usuário convidado com sucesso", { activeClass: "success" });
            } else {
              $snackbar.create("Erro ao convidar usuário", { activeClass: "danger" });
              this.errors = response.data.errors;
            }
          }
        }
        catch {
          console.log("Error in saving role")
        }
      },
      async deleteUser() {
        window.$modal.show("user-delete-modal", null, null, "fixed-center",
          { user: this.item, userId: this.userId, currentRoles: this.currentRoles });
      }
    },
  };
</script>

<style lang="scss" scoped>
  .icon {
    &.active {
      background-color: rgb(220,220,220)
    }
  }
</style>

<style lang="scss">
  .warning-reminder {
    background-color: #F0AD4E;
    .checkbox-c {
      div {
        color: white !important;
        i {
          color: white !important;
        }
        p {
          color: white !important;
        }
      }
    }
  }
</style>
