<template>
  <div>
    <div class="list">
      <div class="list-headers flex center">
        <p class="f-10"></p>
        <p class="f-40">Nome</p>
        <p class="f-20 text-center">Usuários associados</p>
        <p class="f-20 text-center">Dashboards associados</p>
      </div>
      <div class="dashboard list-item clickable flex center py-4" v-for="role in roles"
        @click="gotoRoleEdit(role)">
        <i class="fas f-10 fs-20 text-center" :class="role.icon" />
        <p class="m-0 f-40">{{role.name}}</p>
        <p class="m-0 f-20 text-center">{{role.users_count}}</p>
        <p class="m-0 f-20 text-center">{{role.dashboards_count}}</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "roles-list",
    data() {
      return {
        roles: [],
      };
    },
    mounted() {
      this.fetchRoles();
    },
    methods: {
      async fetchRoles() {
        try {
          const response = await axios.get("/api/v1/roles");
          this.roles = response.data.roles
        } catch {
          console.log("Error in fetching roles")
        }
      },
      gotoRoleEdit(role) {
        this.$router.push({ path: `roles/${role.id}/edit` })
      },
    },
  };
</script>
