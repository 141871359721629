<template>
  <div>
    <div class="flex start center mb-5">
      <p class="btn mr-4 f-20 py-2" v-for="tab in tabs"
        @click="activeTab = tab.name"
        :class="{ 'btn-primary': activeTab === tab.name, 'btn-secondary': activeTab !== tab.name}" >{{tab.title}}</p>
    </div>

    <div class="active-tab">
      <div v-show="activeTab === 'infos'">
        <business-form :id="id" />
      </div>

      <div v-show="activeTab === 'dashboards'">
        <business-dashboard-list :id="id" />
      </div>

      <div v-show="activeTab === 'users'">

      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "business-panel",
    data() {
      return {
        activeTab: "infos",
        tabs: [
          {
            name: "infos",
            title: "Dados cadastrais"
          },
          {
            name: "dashboards",
            title: "Dashboards"
          },
          {
            name: "users",
            title: "Usuários"
          },
        ]
      };
    },
    computed: {
      id() {
        return this.$route.params.id;
      }
    },
    methods: {

    },
  };
</script>


