<template>
  <div>
    <p class="fs-18" v-if="entity"> Histórico de visualizações {{entity.prefix}} <span class="bold">{{entity.name}}</span></p>
    <div class="list">
      <div class="list-headers flex center">
        <p class="f-40" v-if="entityClass !== 'user'">Usuário</p>
        <p class="f-40" v-if="entityClass !== 'dashboard'">Dashboard</p>
        <p class="f-20">Iniciado em</p>
        <p class="f-20">Finalizado em</p>
        <p class="f-20">Duração</p>
      </div>
      <div class="dashboard list-item flex center py-4" v-for="view in views">
        <p class="f-40 gs-70 m-0" v-if="entityClass !== 'user'">{{view.user_name}}</p>
        <p class="f-40 gs-70 m-0" v-if="entityClass !== 'dashboard'">{{view.dashboard_name}}</p>
        <p class="f-20 gs-70 m-0">{{view.started_at | moment("DD/MM/YYYY HH:mm:ss")}}</p>
        <p class="f-20 gs-70 m-0">{{view.finished_at | moment("DD/MM/YYYY HH:mm:ss")}}</p>
        <p class="f-20 gs-70 m-0">{{view.duration_interval}}</p>
      </div>
    </div>

    <div class="flex center around h-10 mt-5">
      <pagination v-if="pagination" :page-data="pagination" @input="changePage"/>
    </div>
  </div>
</template>

<script>
  export default {
    name: "views-list",
    data() {
      return {
        views: [],
        entity: null,
        page: 1,
        pagination: null,
      };
    },
    mounted() {
      this.fetchViews();
    },
    computed: {
      entityClass() {
        return this.$route.params.entity_class
      },
      entityId() {
        return this.$route.params.id
      }
    },
    methods: {
      async fetchViews() {
        const params = {
          page: this.page,
        }

        try {
          const url = `/api/v1/views?${this.entityClass}_id=${this.entityId}`
          const response = await axios.get(url, { params });
          this.views = response.data.views
          this.pagination = response.data.pagination;
          this.entity = response.data.entity
        } catch {
          console.log("Error in fetching views")
        }
      },
      changePage(page) {
        this.page = page
        this.fetchViews();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .role {
    background-color: rgb(220,220,220);
  }
</style>
