<template>
  <div class="breadcrumb-container pl-3">
    <p v-for="route in breadcrumbs">
      <span class="breadcrumb-route"
        :class="{ currentPage: route === breadcrumbs[breadcrumbs.length - 1],
        clickable: route !== breadcrumbs[breadcrumbs.length - 1] }"
        @click="navigateTo(route)"
        :style="breadcrumbStyling(route)">
        {{breadcrumbMap[route]}}
      </span>
      <span class="breadcrumb-arrow" v-if="route !== breadcrumbs[breadcrumbs.length - 1]">></span>
    </p>
  </div>
</template>

<script>
  export default {
    name: "breadcrumb",
    props: {

    },
    data() {
      return {
        breadcrumbs: this.$route.meta.breadcrumb
      };
    },
    computed: {
      breadcrumbMap() {
        return {
          "new-role": "Novo nível de acesso",
          "business-list": "Empresas",
          "business-form": "Cadastrar empresa",
          "business-panel": "Painel de controle da empresa",
          "business-dashboards": "Dashboards",
          "business-dashboards-edit": "Editar dashboard",
          "business-dashboards-new": "Inserir dashboard",
          "dashboard-list": "Dashboards",
          "dashboard-show": "Visualizar dashboard",
          "roles-list": "Níveis de acesso",
          "roles-form": "Cadastro de nível de acesso",
          "themes-list": "Temas",
          "themes-form": "Cadastro de tema",
          "users-list": "Listagem de usuários",
          "users-form": "Registro de usuário",
          "report-list": "Listagem de reports",
          "report-form": "Cadastro de report",
          "account-form": "Minha conta",
          "views-list": "Histórico de visualizações",
        }
      },
    },
    watch: {
      "$route"() {
        this.breadcrumbs = this.$route.meta.breadcrumb
      },
    },
    methods: {
      navigateTo(route) {
        this.$router.push({ name: route });
      },
      matchBreadcrumbRoute(route) {
        return this.breadcrumbs.some((breadcrumb) => {
          if(typeof breadcrumb === "object") {
            return this.userData.policies.scoped ? breadcrumb.scoped === route.name :
              breadcrumb.unscoped === route.name;
          }
          return breadcrumb === route.name;
        });
      },
      breadcrumbStyling(route) {
        if (route === this.breadcrumbs[this.breadcrumbs.length - 1]) {
          return {color: this.themeStyle.support}
        } else {
          return {}
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
  .breadcrumb-container {
    align-items: center;
    color: grey;
    display: flex;
    flex: 1;

    p {
      margin: 0;
    }

    .breadcrumb-route {
      font-size: 20px;
      font-color: rgb(80,80,80);
    }
    .breadcrumb-arrow {
      margin: 0 10px;
      font-size: 24px;
    }
    .currentPage {
      font-size: 22px;
      font-weight: bold;
      font-color: rgb(50,50,50)
    }
  }
</style>
