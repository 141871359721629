<template>
  <div class="options-list my-3">
    <div class="options-list-header flex center start clickable"
      @click="show = !show">
      <div class=" f-10 fs-20">
        <i class="fas bold fs-30 fa-caret-up" v-if="show"></i>
        <i class="fas bold fs-30 fa-caret-down" v-else></i>
      </div>
      <p class="f-50 m-0 fs-18">{{ header }}</p>
      <p class="m-0 selection-count ml-5">{{value.length}} {{selectionCount}}</p>
    </div>
    <div class="option-items py-4" v-if="show">
      <p class="details">{{details}}</p>
      <div class="flex start">
        <search v-model="filter" class="f-60" />
      </div>
      <div class="my-4">
        <div class="option flex center start my-4 clickable" v-for="item in filteredItems"
          @click="toggle(item)">
          <i class="fas f-15 fs-20 fa-check-square text-center" v-if="value.includes(item.id)"></i>
          <i class="far fa-square f-15 fs-20 text-center" v-else></i>
          <p class="m-0 f-50">{{item.name}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "options-list",
  props: ["value", "header", "details", "items"],
  data: function () {
    return {
      show: false,
      filter: "",
    }
  },
  computed: {
    selectionCount() {
      const count = this.value.length
      if (count != 1) {
        return "seleções"
      } else {
        return "seleção"
      }
    },
    filteredItems() {
      return this.items.filter((item) => item.name.toLowerCase().includes(this.filter.toLowerCase()))
    }
  },
  methods: {
    toggle(item) {
      if (this.value.includes(item.id)) {
        this.value.splice(this.value.indexOf(item.id), 1)
      } else {
        this.value.push(item.id);
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .options-list {
    .options-list-header {
      background-color: rgb(220,220,220);
      padding: 10px 30px;
      box-shadow: 1px 1px rgb(210,210,210);
      border-radius: 4px;
    }
  }

  .search-div {
    position: relative;
    height: 40px;
  }
  .search-filter {
    background-position: 10px 12px; /* Position the search icon */
    background-repeat: no-repeat; /* Do not repeat the icon image */
    font-size: 16px; /* Increase font-size */
    padding: 12px 20px 12px 40px; /* Add some padding */
    border: 1px solid #ddd; /* Add a grey border */
    height: 100%;
    width: 100%;
  }
  .search-icon {
    position: absolute;
    top: 10px;
    font-size: 20px;
    left: 10px;
    color: rgb(120,120,120);
  }
</style>
