<template>
  <div class="input-box my-3" :class="{'no-margin': noMargin}">
    <!-- <label class="label" :class="{active: active}">{{title}}</label> -->
    <input :type="inputType" ref="input" class="input" :class="{active: active, error: error, 'no-margin': noMargin}"
      :value="value" @input="$emit('input', $event.target.value)"
      :placeholder="placeholder" @focus="active = true" @blur = "active = false"
      :disabled="disabled"/>
    <p v-if="error" class="text-danger error-content m-0 pl-3">{{error}}</p>
  </div>
</template>

<script>
export default {
  name: "input-box",
  props: ["value", "placeholder", "title", "error", "disabled", "mask", "moneymask", "noMargin", "type"],
  data: function () {
    return {
      active: false,
    }
  },
  computed: {
    inputType() {
      return this.type ? this.type : "text"
    }
  },
  mounted() {
    if (this.mask) {
      Inputmask(this.mask).mask(this.$refs.input);
    }
  }
}
</script>

<style scoped lang="scss">
  .input-box {
    position: relative;

    .error-content {
      position: absolute;
      bottom: -4px;
      left: -8px;
    }
  }

  .input {
    border: 1px solid rgba(100,100,100,1);
    border-radius: 3px;
    height: 40px;
    width: 100%;
    padding: 0 20px;
    margin-bottom: 20px;

    &.error {
      border: 1px solid #D9534F;
      box-shadow: 2px 2px #ffc4c2;
    }
  }

  .label {
    position: absolute;
    top: -12px;
    left: 20px;
    padding: 0 10px;
    background: white;

    &.active {
      color: rgba(100,100,150,1);
    }
  }

  .no-margin {
    margin: 0 !important;
  }
</style>
